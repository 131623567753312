class MyTools {
  constructor(link) {
    this.link = link;
    this.dropdown = $('#my_tools_dropdown');
    this.link.on('click', e => {
      if (!this.dropdown.hasClass('loaded') && !this.dropdown.hasClass('loading')) {
        this.dropdown.addClass('loading');
        $.getScript('/my_tools');
      }
    });
  }
};

$(document).on('turbolinks:load', function() {
  const link = $('a#my_tools_link');
  if (!(link.length > 0)) { return; }
  new MyTools(link);
});
