class DateRangeModal {
  constructor(modal) {
    this.modal = modal;
    this.modalForm = modal.find('form');
    this.filtersForm = $('#filters_dropdown_menu form');
    this.dateRangeSelect = this.filtersForm.find('select#date_range');
    this.customDateRange = this.filtersForm.find('#custom_date_range');

    this.dateRangeSelect.on('change', (e) => {
      if (this.dateRangeSelect.val() == 'custom') {
        this.modal.modal('show');
      }
    });

    if (this.filtersForm.length) {
      this.modalForm.on('submit', e => {
        this.updateFiltersForm();
        this.modal.modal('hide');
        return false;
      });
    }
  }

  updateFiltersForm() {
    const from = this.modalForm.find('input#from').val();
    const to = this.modalForm.find('input#to').val();

    this.filtersForm.find('input#from').val(from);
    this.filtersForm.find('input#to').val(to);

    const editLink = '<a href="#" data-toggle="modal" data-target="#date-range-modal">Edit</a>'
    this.customDateRange.html(`${moment(from).format('LL')} - ${moment(to).format('LL')} ${editLink}`);
    this.customDateRange.removeClass('d-none');
  }
};

$(document).on('turbolinks:load', function() {
  const modal = $('#date-range-modal');
  if (!(modal.length > 0)) { return; }
  new DateRangeModal(modal);
});