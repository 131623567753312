$(document).on('click', 'a[data-insert][data-target]', (e) => {
  const link = $(e.currentTarget);
  const target = $(link.data('target'));
  if (!target.length)
    return false;
  const caretPos = target[0].selectionStart;
  const insert = link.data('insert');
  const val = target.val();

  target.focus();
  target.val(val.substring(0, caretPos) + insert + val.substring(caretPos));
  target[0].selectionStart = caretPos + insert.length;
  target[0].selectionEnd = caretPos + insert.length;

  e.preventDefault();
});
