class VideoCreatorIntroductionForm {
  constructor(form) {
    this.form = form;
    this.purposeSelect = this.form.find('#onboarding_video_creator_introduction_purpose');
    this.otherPurpose = this.form.find('#onboarding_video_creator_introduction_other_purpose');

    this.purposeSelect.on('change', (e) => {
      this.otherPurpose.toggleClass('d-none', e.target.value != 'Other');
    });
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('body.onboarding-video_creator.introduction form#onboarding_main_form');
  if (!(form.length > 0)) { return; }
  new VideoCreatorIntroductionForm(form);
});
