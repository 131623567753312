import Loader from './loader';

class Dashboard {
  constructor() {
    this.resetLoaders();
    $.getScript('/dashboard/banners');
    $.getScript('/dashboard/donations');
    $.getScript('/dashboard/donors');
    $.getScript('/dashboard/campaigns');
    $.getScript('/dashboard/tasks');
  }

  resetLoaders() {
    $('.loader-wrap:not(#activity_feed)').each(function() {
      new Loader($(this)).reset();
    });
  }
};

$(document).on('turbolinks:load', function() {
  if (!($('body.dashboard.show').length > 0)) { return; }
  new Dashboard;
});
