class ShareFormModal {
  constructor(modal) {
    this.modal = modal;
    this.shareLinks = $('a[data-target="#share_url_modal"]');
    this.shareLinks.click(event => {
      this.setUrl($(event.target));
    });
    this.urlInput = this.modal.find('#share_item_url');
  }

  setUrl(link) {
    let url = link.closest('.slat').data('url') || link.closest('.media').data('url')
    this.urlInput.val(url);
  }
};

$(document).on('turbolinks:load', function() {
  const modal = $('#share_url_modal');
  if (!(modal.length > 0)) { return; }
  new ShareFormModal(modal);
});
