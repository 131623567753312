import Loader from './loader';

class PublicPledgeForm {
  constructor(form) {
    this.form = form;
    this.pledgeAmountField = this.form.find('#pledge_pledge_amount');
    this.startDateField = this.form.find('#pledge_start_date');
    this.endDateField = this.form.find('#pledge_end_date');
    this.loader = new Loader(this.form.find('.loader-wrap'));

    this.pledgeAmountField.on('change', () => {
      this.calculateInstallments();
    });

    this.form.find('#pledge_pledge_amount, #pledge_start_date, #pledge_end_date').on('dp.change', () => {
      this.calculateInstallments();
    });

    this.calculateInstallments();
  }

  calculateInstallments() {
    const pledgeAmount = this.pledgeAmountField.val();
    const startDate = this.startDateField.val();
    const endDate = this.endDateField.val();

    if (!pledgeAmount || !startDate || !endDate) {
      this.clearInstallments();
    } else {
      this.loader.reset(false);

      let url = "/forms/pledges/calculate_installments";
      url += `?pledge_amount=${pledgeAmount}`;
      url += `&start_date=${startDate}`;
      url += `&end_date=${endDate}`;
      $.get(url, data => {
        if (!data) {
          this.clearInstallments();
        } else {
          $.each(data, (key, value) => {
            this.form.find(`#${key}_installment`).text(value);
          });
        }
        this.loader.loaded();
      });
    }
  }

  clearInstallments() {
    this.form.find('.installment').text('');
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#public_pledge_form');
  if (!(form.length > 0)) { return; }
  new PublicPledgeForm(form);
});
