class SideNav {
  constructor(nav) {
    this.nav = nav;
    this.wrap = this.nav.next('.container-wrap');
    this.topbanner = $('.jumbotron.top-of-page-banner');

    $('#open_side_nav').on('click', event => {
      this.toggle();
      event.preventDefault();
    });

    this.nav.find('.nav-pills > ul.site-nav-section > li:has(.collapse) > a').click(this.toggleSubMenu);
    this.nav.find('.nav-pills > ul.site-nav-section > li:has(.collapse) > a.active').each(function() {
      $(this).next('.collapse').addClass('show in');
    });
    this.expandSubMenusWithActiveItem();
  }

  toggle() {
    this.nav.toggleClass('nav-slide');
    this.wrap.toggleClass('nav-slide');
    this.topbanner.toggleClass('nav-slide');
  }

  toggleSubMenu() {
    const listItem = $(this).parent();
    listItem.children().toggleClass('active');

    const caretIcon = $(this).find('i.fa.pull-right, i.fa.float-right');

    let openCaret = 'down';
    if (listItem.hasClass('nav-profile')) {
      openCaret = 'up';
    }

    if (caretIcon.length) {
      if (caretIcon.hasClass('fa-caret-right')) {
        caretIcon.addClass(`fa-caret-${openCaret}`).removeClass('fa-caret-right');
      } else {
        caretIcon.addClass('fa-caret-right').removeClass(`fa-caret-${openCaret}`);
      }
    }
  }

  expandSubMenusWithActiveItem() {
    this.nav.find('.collapse').each(function() {
      const link = $(this).prev();

      if ($(this).find('a.active').length) {
        if (!$(this).hasClass('show')) {
          $(this).addClass('show in');
        }
        if (!link.hasClass('active')) {
          link.addClass('active');
        }
        link.find('i.fa.pull-right, i.fa.float-right').addClass('fa-caret-down').removeClass('fa-caret-right');
      }

      if (!$(this).children().length) {
        link.hide();
      }
    });
  }
};

$(document).on('turbolinks:load', function() {
  const nav = $('nav.site-nav');
  if (!(nav.length > 0)) { return; }
  new SideNav(nav);
});
