import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["labelType", "labelGreeting"]

  connect() {
    this.toggleLabelGreeting();
  }

  toggleLabelGreeting() {
    if (!!this.labelTypeTarget.value) {
      this.labelGreetingTarget.classList.remove('d-none')
    } else {
      this.labelGreetingTarget.classList.add('d-none')
    }
  }
}
