$(document).on('turbolinks:load', function() {
  const el = $("[data-toggle='refresh-iframe']");
  if (!(el.length > 0)) { return; }
  
  const targetIframe = el.data('target');
  el.click((e) => {
    e.preventDefault();
    $(targetIframe,window.parent.document).attr('src',$(targetIframe,window.parent.document).attr('src'));
  });
});
