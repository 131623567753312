import accounting from 'accounting'
import pluralize from 'pluralize'

class Slats {
  constructor(el) {
    this.el = el;
    this.isGrid = this.el.find('.slat-grid').length > 0;
    this.header = this.el.find('.slat-header');
    this.selectedCount = $('#list_selected_count');

    if (this.isGrid) {
      this.list = this.el.find('.slat-grid');
    } else {
      this.list = this.el.find('.slat-list');
    }

    this.contentHeader = $('.filter-tools');
    this.contentHeader.find('.btn-group-filters a').on('click', event => {
      this.contentHeader.find('a').removeClass('active');
    });

    this.allIds = gon.all_ids;
    this.selectedIds = gon.selected_ids;
    this.paramsChecksum = gon.params_checksum;

    this.bulkActions = $('.bulk-actions');
    this.bulkActionBtns = this.bulkActions.find('.btn');
    if (!this.paramsChecksum) {
      this.bulkActions.find('a').on('click', event => {
        this.appendIdsToBulkActionPath($(event.target));
      });
    }

    this.selectAllCheckbox = this.header.find(':checkbox');
    this.selectAllCheckbox.on('click', event => {
      this.selectAll($(event.target).is(':checked'));
    });
    this.list.find(':checkbox').on('click', event => {
      this.selectOne($(event.target));
    });

    if (this.list.attr('data-sort-url')) {
      this.initSortable();
    }

    if (this.selectedIds) {
      this.selectedItemsFromSelectedIds();
    }

    this.bulkDeleteModal = $('#bulk_delete_modal');
    if (this.bulkDeleteModal.length) {
      this.bulkDeleteModal.on('show.bs.modal', event => {
        const selectedCount = this.bulkDeleteModal.find('.selected-count');
        selectedCount.text(pluralize(selectedCount.data('resource-name'), this.getSelectedCount(), true));

        if (!this.paramsChecksum) {
          const form = this.bulkDeleteModal.find('form');
          form.find('input:hidden[name="id[]"]').remove();
          this.list.find('input:checked').each(function() {
            form.prepend(`<input type="hidden" name="id[]" value="${$(this).val()}"/>`);
          });
        }
      });
    }
  }

  selectAll(checked) {
    const checkboxes = this.list.find(':checkbox');
    checkboxes.prop('checked', checked).parents('.card-wrapper').toggleClass('selected', checked);

    if (this.selectedIds) {
      const ids = checked ? this.allIds : [];
      this.updateSelectedIds('set', ids);
    }

    this.toggleBulkActions();
    this.updateSelectedCount();
  }

  selectOne(target) {
    const checked = target.is(':checked');
    target.parents('.card-wrapper').toggleClass('selected', checked);

    if (this.selectedIds) {
      const command = checked ? 'add' : 'remove';
      const id = parseInt(target.val());
      this.updateSelectedIds(command, [id]);
    }

    this.toggleBulkActions();
    this.updateSelectedCount();
  }

  toggleBulkActions() {
    // Sometimes (export) we display a tooltip on disabled bulk action buttons.
    // When items are selected, we remove those tooltips.
    const tooltips = this.bulkActions.find('[data-toggle="tooltip"]');

    if (this.getSelectedCount() > 0) {
      this.bulkActionBtns.removeClass('disabled');
      tooltips.tooltip('disable');
    } else {
      this.bulkActionBtns.addClass('disabled');
      tooltips.tooltip('enable');
    }
  }

  getSelectedCount() {
    if (this.selectedIds) {
      return this.selectedIds.length;
    } else {
      return this.list.find('input:checked').length;
    }
  }

  updateSelectedCount() {
    const count = this.getSelectedCount();
    this.selectedCount.text(accounting.formatNumber(count));
    this.selectedCount.parent().toggleClass('disabled', count === 0)
  }

  appendIdsToBulkActionPath(link) {
    if (link.hasClass('dropdown-toggle')) {
      return;
    }

    if (link.attr('href') === 'javascript:;') {
      return;
    }

    let idParam = link.data('id-param') || 'id';
    idParam += '[]';

    let path = link.attr('href');
    if (path.indexOf('?') !== -1) {
      path += '&';
    } else {
      path += '?';
    }

    this.list.find('input:checked').each(function() {
      path += idParam + '=' + $(this).val() + '&';
    });

    link.attr('href', path);
  }

  initSortable() {
    let container;
    const options = {
      connectWith: '.slat-list',
      handle: 'a.sort',
      update(event, ui) {
        const sortId = ui.item.data('sort-id') || ui.item.data('id');

        let params = '_method=put';
        params += '&type=to_position';
        params += `&id=${sortId}`;
        params += `&position=${ui.item.index() + 1}`;

        $.ajax({
          type: 'POST',
          dataType: 'script',
          url: ui.item.closest('[data-sort-url]').data('sort-url'),
          data: params
        });
      }
    };

    if (this.isGrid) {
      container = this.list.find('.slat');
    } else {
      container = this.list;
      options.axis = 'y';
    }

    container.sortable(options);
  }

  // supported commands are set, add, and remove
  // ids represents an array of selected items
  updateSelectedIds(command, ids) {
    $.post('/search_selections', {
      command,
      params_checksum: this.paramsChecksum,
      ids
    }
    );

    if (command === 'set') {
      this.selectedIds = ids;
    } else {
      $.each(ids, (index, id) => {
        const position = this.selectedIds.indexOf(id);
        if (command === 'add') {
          if (position === -1) { this.selectedIds.push(id); }
        } else if (command === 'remove') {
          if (position > -1) { this.selectedIds.splice(position, 1); }
        }
      });
    }

    this.el.find('#selected-ids').attr('data-value', `[${this.selectedIds}]`);
  }

  selectedItemsFromSelectedIds() {
    if (this.selectedIds.length > 0) {
      $.each(this.selectedIds, (index, id) => {
        const checkbox = this.list.find(`:checkbox[value="${id}"]`);
        checkbox.prop('checked', true);
        checkbox.closest('.content-box').addClass('selected');
      });
      this.toggleBulkActions();
      this.updateSelectedCount();
      if (this.selectedIds.length === this.allIds.length) {
        this.selectAllCheckbox.prop('checked', true);
      }
    }
  }
};

$(document).on('turbolinks:load', function() {
  const slats = $('.slats');
  if (!(slats.length > 0)) { return; }
  slats.each(function() {
    new Slats($(this));
  });
});
