$(document).on('click', '[data-target][data-addclass]', function(event) {
  $($(this).data('target')).addClass($(this).data('addclass'));
  if ($(this)[0].tagName === 'A') { event.preventDefault(); }
});

$(document).on('click', '[data-target][data-removeclass]', function(event) {
  $($(this).data('target')).removeClass($(this).data('removeclass'));
  if ($(this)[0].tagName === 'A') { event.preventDefault(); }
});

$(document).on('click', '[data-target][data-toggleclass]', function(event) {
  $($(this).data('target')).toggleClass($(this).data('toggleclass'));
  if ($(this)[0].tagName === 'A') { event.preventDefault(); }
});

$(document).on('turbolinks:load', function() {
  $('[data-target][data-addclass]').each(function() {
    if ($(this).is(':checked')) {
      $($(this).data('target')).addClass($(this).data('addclass'));
    }
  });

  $('[data-target][data-removeclass]').each(function() {
    if ($(this).is(':checked')) {
      $($(this).data('target')).removeClass($(this).data('removeclass'));
    }
  });

  $('[data-target][data-toggleclass]').each(function() {
    if ($(this).is(':checked')) {
      $($(this).data('target')).toggleClass($(this).data('toggleclass'));
    }
  });
});
