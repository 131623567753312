class TaskList {
  constructor(taskList) {
    // This prevents duplicate events if we navigate to this page multiple times
    // in between page loads.
    this.taskList = taskList;
    this.taskList.off('click', '.task a');

    this.taskList.on('click', '.task a', event => {
      const taskId = $(event.currentTarget).closest('.task').data('id');
      if($(event.target).hasClass('custom-control-label')) {
        $.getScript(`/tasks/${taskId}/complete`);
        event.preventDefault();
      } else if (!this.isMobile()) {
        $.getScript(`/tasks/${taskId}`);
        event.preventDefault();
      }
    });
  }

  isMobile() {
    return $('body').hasClass('mobile');
  }
};

$(document).on('turbolinks:load', function() {
  const taskList = $('body.tasks.index #task_list');
  if (!(taskList.length > 0)) { return; }
  new TaskList($(this));
});
