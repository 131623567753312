import Loader from './loader';

class UserProfile {
  constructor() {
    this.resetLoaders();
    this.loadSections();

    $(window).scroll(event => {
      this.loadSections();
    });
  }

  resetLoaders() {
    $('.loader-wrap:not(#activity_feed)').each(function() {
      new Loader($(this)).reset();
    });
  }

  loadSections() {
    const userId = gon.user_id;
    this.loadSection(`/user_profiles/${userId}/giving`, '#donations');
    this.loadSection(`/user_profiles/${userId}/soft_credits`, '#soft_credits');
    this.loadSection(`/user_profiles/${userId}/pledges`, '#pledges');
    this.loadSection(`/user_profiles/${userId}/tasks`, '#tasks');
    this.loadSection(`/user_profiles/${userId}/groups`, '#groups');
    this.loadSection(`/user_profiles/${userId}/relationships`, '#relationships');
    this.loadSection(`/user_profiles/${userId}/notes`, '#notes');
    this.loadSection(`/user_profiles/${userId}/communication`, '#communication');
  }

  loadSection(endpoint, selector) {
    const elem = $(selector);
    if (!(elem.length > 0)) { return; }

    // Many of sections on the user profile that load via ajax are below the fold
    // of the page and only visible to the admin if he/she scrolls down.  By checking
    // whether or not the section is actually visible before we load it we can save
    // our servers some work.
    const loaderWrap = elem.find('.loader-wrap');
    const alreadyLoadedOrLoading = (loaderWrap.hasClass('loaded') || loaderWrap.hasClass('loading'));

    if (!alreadyLoadedOrLoading && this.isScrolledIntoView(elem)) {
      loaderWrap.addClass('loading');
      $.getScript(endpoint);
    }
  }

  isScrolledIntoView(elem) {
    const docViewTop = $(window).scrollTop();
    const docViewBottom = docViewTop + $(window).height();
    const elemTop = $(elem).offset().top;
    const elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }
};

$(document).on('turbolinks:load', function() {
  if (!($('body.users.show').length > 0)) { return; }
  new UserProfile;
});
