import Handlebars from 'handlebars'

export default class AddressFormFields {
  constructor(addressField) {
    this.addressField = addressField;
    this.container = this.addressField.closest('.address-fields');
    this.objectType = this.container.data("objectType");
    this.type = this.getAddressType();
    this.address2Field = this.container.find(`#${this.objectType}_${this.type}_address_attributes_address_2`);
    this.cityField = this.container.find(`#${this.objectType}_${this.type}_address_attributes_city`);
    this.zipCodeField = this.container.find(`#${this.objectType}_${this.type}_address_attributes_zip_code`);
    this.countryField = this.container.find(`#${this.objectType}_${this.type}_address_attributes_country`);
    this.stateContainer = this.container.find(`#${this.type}_address_state_container`);
    this.updateAddressField = this.container.next(`#update_${this.type}_address_field`);
    this.skipAddressNormalizationField = $(`#skip_${this.type}_address_normalization_field`);

    let source = this.container.find(`#${this.type}_address_us_states`).html();
    this.usStatesTemplate = Handlebars.compile(source);

    source = this.container.find(`#${this.type}_address_ca_provinces`).html();
    this.caProvincesTemplate = Handlebars.compile(source);

    source = this.container.find(`#${this.type}_address_state_textbox`).html();
    this.stateTextboxTemplate = Handlebars.compile(source);

    this.countryField.on('change', event => {
      const country = $(event.target).val();
      this.updateStateContainer(country);
    });
    this.updateStateContainer(this.countryField.val());

    this.initTypeahead();

    this.container.find(`[id^=${this.objectType}_${this.type}_address_attributes_]:text`).on('keyup', e => {
      this.handleAddressFieldChange($(e.currentTarget));
    });
    this.container.find(`select[id^=${this.objectType}_${this.type}_address_attributes_]`).on('change', e => {
      this.handleAddressFieldChange($(e.currentTarget));
    });
  }

  handleAddressFieldChange(formElement) {
    let isDefaultValue;
    if (formElement.is('select')) {
      isDefaultValue = !formElement.find('option:selected')[0].defaultSelected;
    } else {
      isDefaultValue = formElement.val() !== formElement[0].defaultValue;
    }

    if (isDefaultValue) {
      this.updateAddressField.removeClass('d-none');
      this.skipAddressNormalizationField.removeClass('d-none');
    } else {
      this.updateAddressField.addClass('d-none');
      this.skipAddressNormalizationField.addClass('d-none');
    }
  }

  getAddressType() {
    const id = this.addressField.attr('id');
    const regex = /_(.+?)_address/;
    const matches = regex.exec(id);
    return matches[1];
  }

  updateStateContainer(country) {
    let template;
    if (!country) { country = 'US'; }

    if (country === 'US') {
      if (this.stateContainer.find('select[data-country="US"]').length) { return; }
      template = this.usStatesTemplate;
    } else if (country === 'CA') {
      template = this.caProvincesTemplate;
    } else {
      if (this.stateContainer.find('input').length) { return; }
      template = this.stateTextboxTemplate;
    }

    this.stateContainer.html(template());
    this.stateField = this.stateContainer.find(`#${this.objectType}_${this.type}_address_attributes_state`);
  }

  initTypeahead() {
    const bloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: `/addresses.json?q[address_type_eq]=${this.type}&q[address_cont]=%QUERY`,
        wildcard: '%QUERY'
      }
    });

    bloodhound.initialize();

    this.typeahead = this.addressField.typeahead(null, {
      name: this.addressField.id,
      displayKey: 'address',
      limit: Infinity,
      source: bloodhound.ttAdapter(),
      templates: {
        suggestion: Handlebars.compile("<p class='m-a-0 m-0'>{{full_address}}</p>")
      }
    }

    ).on('typeahead:selected', (e, address) => {
      this.zipCodeField.val(address.zip_code);
      this.cityField.val(address.city);
      this.stateField.val(address.state);
      this.address2Field.val(address.address_2);
      if (address.geo_id) {
        $.getJSON(`/addresses/address_details_from_google_id?geo_id=${address.geo_id}`, address => {
          this.countryField.val(address.country);
          this.updateStateContainer(address.country);
          this.cityField.val(address.city);
          this.zipCodeField.val(address.zip_code);
          this.stateField.val(address.state);
        });
      }
    });
  }
};

$(document).on('turbolinks:load', function() {
  const fields = $('.address-fields input[name*="[address]"]');
  if (!(fields.length > 0)) { return; }
  fields.each(function() {
    new AddressFormFields($(this));
  });
});
