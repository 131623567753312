import Alert from './alert';

((($, Bee, undefined) => {
  Bee.init = () => {
    $.ajax({ url: '/bee/token', success: Bee.create});
  };

  Bee.create = token => {
    BeePlugin.create(token, Bee.config(), Bee.on_create);
  };

  Bee.config = () => {
    const dataAttrs = Bee.data_attrs();

    return {
      container: 'bee_plugin_container',
      uid: dataAttrs.uid,
      mergeTags: dataAttrs.mergeTags,
      specialLinks: dataAttrs.specialLinks,
      autosave: true,
      language: 'en-US',
      preventClose: false,
      onLoad: Bee.on_load,
      onSave: Bee.on_save,
      onSaveAsTemplate: Bee.on_save_as_template,
      onAutoSave: Bee.on_auto_save,
      onChange: Bee.on_change,
      onSend: Bee.on_send,
      onError: Bee.on_error,
      rowsConfiguration: {
        emptyRows: true,
        defaultRows: true,
        externalContentURLs: [{
          name: 'Saved Rows',
          value: dataAttrs.savedRowsUrl
        }]
      },
      contentDialog: {
        saveRow: {
          handler: function (resolve, reject, args) {
            // save the resolve method in a window variable so we can
            // call it later in bee_saved_rows/create_or_update.js.erb
            window.bee.saveRowResolve = resolve

            var modal = $('#bee_saved_rows_modal');

            // updates the json hidden field after the modal is populated and
            // shown by new_or_edit.js.erb
            modal.on('shown.bs.modal', e => {
              modal.find('#bee_saved_row_json').val(JSON.stringify(args));
            })

            // calls the reject method if the admin closes the modal before saving
            modal.on('hidden.bs.modal', reject);

            if (args.metadata)
              $.getScript(`/bee_saved_rows/${args.metadata.id}/edit`)
            else
              $.getScript('/bee_saved_rows/new')
          }
        }
      }
    }
  };

  Bee.on_create = bee => {
    const dataAttrs = Bee.data_attrs();
    $.ajax({ url: dataAttrs.template, success: Bee.on_load_template });
    window.bee = bee;
    window.bee.hasChanges = false;
  };

  Bee.on_load_template = template => {
    window.bee.start(JSON.parse(template));
  };

  Bee.on_save = (jsonFile, htmlFile) => {
    const dataAttrs = Bee.data_attrs();
    const form = $('form#onboarding_main_form');

    const saveAndExitLink = $('a#save_and_exit');

    $.post('/bee/save', { email_id: dataAttrs.emailId, json: jsonFile, html: htmlFile, updated_at: Bee.get_updated_at() })
      .done((data) => {
        Bee.set_updated_at(data.updated_at);

        // We don't want to submit the form and advance the onboarder if we're saving
        // in order to send a test email.
        var testModal = $('#email_test_modal:visible');
        if (!testModal.length)
          form.off('submit').submit();
        else
          testModal.find('a.submit').removeClass('disabled');
      })
      .fail((xhr, status, error) => {
        new Alert('Save has failed. ' + (xhr.responseJSON.error || Bee.generic_error_msg()), 'error', false).display();
        Rails.enableFormElements(form[0]);
        saveAndExitLink.removeClass('disabled');
      });
  };

  Bee.on_auto_save = jsonFile => {
    // We only want to fire off an autosave request if changes have been made since
    // the last autosave.  If an admin leaves an emails open in a browser tab, this
    // will protect us from getting requests every 15 seconds for hours on end.
    if (!window.bee.hasChanges)
      return;

    const dataAttrs = Bee.data_attrs();

    $.post('/bee/autosave', { email_id: dataAttrs.emailId, json: jsonFile, updated_at: Bee.get_updated_at() })
      .done((data) => {
        Bee.set_updated_at(data.updated_at);
        window.bee.hasChanges = false;
      })
      .fail((xhr, status, error) => {
        new Alert('Autosave has failed. ' + (xhr.responseJSON.error ||  Bee.generic_error_msg()), 'error', false).display();
      });
  };

  Bee.data_attrs = () => {
    return $('#bee_plugin_container').data() || {};
  };

  Bee.get_updated_at = () => {
    if(!!Bee.updatedAt)
      return Bee.updatedAt;
    else {
      return Bee.data_attrs().updatedAt;
    }
  };

  Bee.set_updated_at = updatedAt => {
    Bee.updatedAt = updatedAt;
  };

  Bee.generic_error_msg = () => {
    return 'Please contact support if this issue continues and we will assist you.';
  };

  Bee.on_save_as_template = jsonFile => {
  };

  Bee.on_send = htmlFile => {
  };

  Bee.on_error = errorMessages => {
    console.log('onError ', errorMessages);
  };

  Bee.on_change = (jsonFile, response) => {
    window.bee.hasChanges = true;
  };

  Bee.on_load = () => {
    $(window.top.document.body).find('#nfg-overlay').remove();
  }
})(jQuery, window.Bee = window.Bee || {}));

$(document).on('turbolinks:load', () => {
  if($('#bee_plugin_container').length) {
    // even though the links are disabled, the links can still be clicked on
    // as per bootstrap documentation, disabled only makes them look disabled but doesn't actually disable them
    // https://getbootstrap.com/docs/4.0/components/buttons/
    // so adding an overlay that shows and goes away.  It works pretty good
    var $nfgOverlay = $('<div id="nfg-overlay" class="modal-backdrop show">')
    $(window.top.document.body).prepend($nfgOverlay);
    Bee.init();
  }
});
