import Loader from './loader';

export class ActivityFeed {
  constructor(feed) {
    this.feed = feed;
    this.loader = new Loader(this.feed);
    this.filters = $('.activity-feed-filters a');
    this.filters.on('click', event => {
      $('#activity_feed_list').addClass('loading');
      this.filterActivities($(event.target));
    });

    $('#view_more_activities_button').click(event => {
      this.loadNextPage();
      event.preventDefault();
    });

    this.loadActivities();
  }

  loadActivities() {
    this.loader.reset(false);
    $.getScript(`/activities?${this.additionalParams()}`);
  }

  loadNextPage() {
    const nextPageLink = this.feed.find('#display_more_activities a[rel="next"]');
    if (nextPageLink.length) {
      this.loader.reset(false);
      $.getScript(nextPageLink.attr('href') + this.additionalParams());
    }
  }

  filterActivities(link) {
    this.filters.removeClass('active');
    link.addClass('active');
    this.filters.closest('.dropdown-menu').prev('a').find('span').text(`Showing ${link.text()}`);
    this.loadActivities();
    $('#activity_feed_list').removeClass('loading');
  }

  additionalParams() {
    let params = '';
    if (this.feed.data('owner-id') && this.feed.data('owner-type')) {
      params += `&owner_type=${this.feed.data('owner-type')}`
      let ownerIds = String(this.feed.data('owner-id')).split(',')
      $.each(ownerIds, (index, ownerId) =>
        params += `&owner_id[]=${ownerId}`
      );
    }
    if (this.feed.data('trackable-id') && this.feed.data('trackable-type')) {
      params += `&trackable_type=${this.feed.data('trackable-type')}&trackable_id=${this.feed.data('trackable-id')}`;
    }
    if (this.feed.data('recipient-id') && this.feed.data('recipient-type')) {
      params += `&recipient_type=${this.feed.data('recipient-type')}`
      let recipientIds = String(this.feed.data('recipient-id')).split(',')
      $.each(recipientIds, (index, recipientId) =>
        params += `&recipient_id[]=${recipientId}`
      );
    }
    if (this.filters.length) {
      params += `&type=${this.filters.filter('.active').data('filter')}`;
    }
    return params;
  }
};

$(document).on('turbolinks:load', function() {
  const feed = $('#activity_feed');
  if (!(feed.length > 0)) { return; }
  new ActivityFeed(feed);
});
