import ahoy from 'ahoy'

class VideoShareModals {
  constructor(shareModal) {
    this.shareModal = shareModal;
    this.videoPlayer = $('#video_player');
    this.videoViewShareNudgeModal = $('#video_view_share_nudge_modal');

    // Prompt the donor to share their video when the video ends
    this.videoPlayer.on('ended', e => {
      if (!$('.video-share-link.clicked').length > 0)
        this.videoViewShareNudgeModal.modal('show');
    });

    $('.video-share-link').on('click', e => {
      $(e.currentTarget).addClass('clicked');

      const modal = $(e.currentTarget).closest('.modal')
      if (modal.length) {
        $(event.currentTarget).addClass('d-none');
        modal.find('#share_links').removeClass('d-none');
        event.preventDefault();
      }
    });

    $('#video_view_share_nudge_modal, #like_comment_share_nudge_modal').on('shown.bs.modal', e => {
      const modal = $(e.target);
      ahoy.track('Viewed share nudge modal', { id: modal.attr('id'), page: window.location.pathname });
    });
  }
};

$(document).on('turbolinks:load', function() {
  const shareModal = $('#video_share_modal');
  if (!(shareModal.length > 0)) { return; }
  new VideoShareModals(shareModal);
});