$(document).on('turbolinks:load', () =>
  $('.redactor').each(function() {
    // [#DM-6239] This line fixes an issue w/Redactor + Turbolinks
    // where you end up with two editors after clicking back.
    $(this).closest('.redactor-box').replaceWith($(this));

    const options = $(this).data('options') || {};
    $(this).redactor(options);
  })
);
