// app/javascript/controllers/donor_controller.js
import { Controller } from "stimulus";

/**
 * Controller for managing co-donors in the donation form.
 */
export default class extends Controller {
  static targets = ["userSelect", "coDonors"]

  /**
   * Initializes the controller by binding the change event on the user select2 select box
   * and populating the co-donors when editing a donation.
   */
  connect() {
    this.bindSelect2ChangeEvent(); // listen for the change event on the user select2 select box
    this.updateCoDonors(); // call the function when the page loads to populate the co-donors when editing a donation
  }

  /**
   * Binds a change event to the Select2 element with the ID 'donation_user_id'.
   * When an item is selected in the Select2 dropdown, a native 'change' event is dispatched.
   */
  bindSelect2ChangeEvent() {
    $("#donation_user_id").on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

  /**
   * Updates the co-donors list based on the selected user.
   * 
   * This function clears the current co-donors list and fetches the co-donors
   * associated with the selected user from the server. If co-donors are found,
   * it updates the target element with the new co-donors.
   * 
   */
  updateCoDonors() {
    const userId = this.userSelectTarget.value;
    const donationId = this.userSelectTarget.dataset.donation; // This will be available while editing the donation.
    const targetElement = this.coDonorsTarget;

    targetElement.innerHTML = ""; // clear out the old options

    if (userId) {
      fetch(`/users/${userId}/co_donors?donation_id=${donationId}`) // make an AJAX request to the server
        .then(response => response.json()) // parse the response as JSON
        .then(data => { // "data" is the parsed JSON object returned from the above `response.json()` call
          if (data.length > 0) { // if there are co-donors
            targetElement.innerHTML = '<label for="co_donors"><span class="required">*</span> Co-Donor</label>';
            targetElement.innerHTML += data.map(coDonor => this.generateCheckbox(coDonor, donationId)).join('');
          }
        })
        .catch(error => {
          console.error("There was an error fetching the co-donors!", error);
        });
    }
  }

  /**
   * Generates an HTML string for a checkbox input element representing a co-donor.
   *
   */
  generateCheckbox(coDonor, donationId) {
    /**
     * Determines if the donation ID is included in the coDonor's donation IDs.
     * If donationId is not provided, defaults to true.
     */

    /**
     * Extracts the donation co-donor IDs from the coDonor object.
     *
     */
    const { donation_co_donor_ids: donationCoDonors } = coDonor;
    /**
     * Destructures the `checkedCoDonors` and `currentAction` properties from the `dataset` of the `coDonorsTarget` element.
     */
    const { checkedCoDonors, action: currentAction } = this.coDonorsTarget.dataset;
    let isChecked;

    if (['create', 'update'].includes(currentAction)) {
      isChecked = checkedCoDonors ? checkedCoDonors.includes(coDonor.id) : false;
    } else {
      isChecked = donationId ? donationCoDonors.includes(parseInt(coDonor.id)) : true;
    }

    return `
      <div class="custom-control custom-checkbox">
        <input type="checkbox" name="donation[co_donor_ids][]" value="${coDonor.id}" class="custom-control-input" id="co_donor_${coDonor.id}" data-target="co-donors.coDonors" ${isChecked ? 'checked' : ''}>
        <label class="custom-control-label" for="co_donor_${coDonor.id}">${coDonor.name}</label>
      </div>
    `;
  }
}
