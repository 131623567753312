class VideoCreatorSetupForm {
  constructor(form) {
    this.form = form;
    this.contentTextarea = this.form.find('#onboarding_video_creator_setup_content');
    this.contentTextarea.redactor({
      paragraphize: false,
      linebreaks: true,
      plugins: ['mergefields'],
      mergefieldsJson: this.contentTextarea.data('mergeFields'),
      source: false
    });
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('body.onboarding-video_creator.setup form#onboarding_main_form');
  if (!(form.length > 0)) { return; }
  new VideoCreatorSetupForm(form);
});
