class AcknowledgementSidebar {
  constructor(el) {
    this.el = el;
    this.templateSelect = this.el.find('select#template_id');
    this.selectedTemplateId = this.templateSelect.val();
    this.templateForm = this.templateSelect.closest('form');
    this.iframeEditor = $('iframe#mailing_editor');
    this.markAsAcknowledgedCheckbox = $(':checkbox#acknowledged');

    this.templateSelect.on('change', e => {
      const newTemplateId = $(e.currentTarget).val();
      if ((newTemplateId !== '') && (newTemplateId !== this.selectedTemplateId)) {
        this.templateForm.submit();
      }
    });

    this.templateForm.on('submit', e => {
      const answer = confirm("Are you sure you want to change to a new template?  All content and settings changes that you've made to this letter will be lost.");
      if (!answer) { this.templateForm[0].reset(); }
      return answer;
    });

    this.saveAsNewTemplateLink = this.el.find('#save_as_new_template_link_in_acknowledgement_sidebar');

    this.iframeEditor.on('load', e => {
      this.iframeEditor.contents().find('body').on('click', 'a.cs-html-editor-save', e => {
        this.saveAsNewTemplateLink.removeClass('disabled');
      });
    });

    this.markAsAcknowledgedCheckbox.on('click', e => {
      const form = $(e.target).closest('form')[0];
      Rails.fire(form, 'submit');
    });
  }
};

$(document).on('turbolinks:load', function() {
  const sidebar = $('body.acknowledgements.show .builder-options');
  if (!(sidebar.length > 0)) { return; }
  new AcknowledgementSidebar(sidebar);
});
