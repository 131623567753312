class EmailCreatorLayoutForm {
  constructor(form) {
    this.form = form;
    this.form.on('submit', () => {
      this.loadOldValues();
      this.loadNewValues();

      if (this.formIsInvalid()) { return; }

      if (this.hasExistingContent() && (this.layoutTypeChanged() || this.templateIdChanged() || this.layoutChanged())) {
        return confirm('WARNING!  This email has existing content that will be lost if you make these changes.  Click \'OK\' if you want to continue?');
      }
    });
  }

  loadOldValues() {
    this.oldLayoutType = this.form.find('input#old_layout_type').val();
    this.oldTemplateId = this.form.find('input#old_template_id').val();
    this.oldLayout = this.form.find('input#old_layout').val();
  }

  loadNewValues() {
    // || '' is needed b/c these can be undefined and undefined != '' in js.
    this.newLayoutType = this.form.find('input[name="onboarding_email_creator_layout[layout_type]"]').val() || '';
    this.newTemplateId = this.form.find('input[name="onboarding_email_creator_layout[template_id]"]:checked').val() || '';
    this.newLayout = this.form.find('input[name="onboarding_email_creator_layout[email_layout_id]"]:checked').val() || '';
  }

  hasExistingContent() {
    return this.form.find('input#has_existing_content').val() === 'true';
  }

  formIsInvalid() {
    return !this.newLayoutType ||
    ((this.newLayoutType === 'saved_template') && !this.newTemplateId) ||
    ((this.newLayoutType === 'starter_layout') && !this.newLayout);
  }

  layoutTypeChanged() {
    return this.oldLayoutType !== this.newLayoutType;
  }

  templateIdChanged() {
    return this.oldTemplateId !== this.newTemplateId;
  }

  layoutChanged() {
    return this.oldLayout !== this.newLayout;
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('body.onboarding-email_creator.layout form#onboarding_main_form');
  if (!(form.length > 0)) { return; }
  new EmailCreatorLayoutForm(form);
});
