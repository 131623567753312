class DuplicateFormModal {
  constructor(modal) {
    this.modal = modal;
    this.form = this.modal.find('form');
    this.duplicateLinks = $('a[data-target="#duplicate_form_modal"]');

    this.duplicateLinks.click(event => {
      this.setFormAction($(event.target));
    });

    this.form.submit(event => {
      return this.validateForm();
    });
  }

  setFormAction(link) {
    let formId = link.closest('.slat').data('id');
    this.modal.find('form').attr('action', `/forms/${formId}/duplicate`)
  }

  validateForm() {
    if (this.form.find('input#name').val() == '') {
      alert('You forgot to give your new form a name.  This is required.');

      // This re-enables the modals submit link in the footer.  If we don't do this,
      // then the user won't be able to add a name and re-submit.
      setTimeout(() => {
        Rails.enableElement(this.modal.find('a.submit')[0]);
      }, 250);

      return false;
    }
    else
      return true;
  }
};

$(document).on('turbolinks:load', function() {
  const modal = $('#duplicate_form_modal');
  if (!(modal.length > 0)) { return; }
  new DuplicateFormModal(modal);
});
