// Render multiple users transactions
//
// example gon setting in controller for a collection:
// def index
//   gon.user_ids = @home_users.collect(&:id)
// end
//
// Example for a single user_id (e.g.: remote action)
// def create
//   if @home_user.save
//     gon.user_ids = [@home_user.id]
//     ...
//   end
// end
//
// Renders a collection of user transaction listings
// Fired automatically when turbolinks is ready
// And can be manually called when re-drawing the entire
// users transactions listings.
import Loader from './loader';

class HouseholdTransactions {
  constructor() {
    this.resetLoaders();
    this.userIds = gon.user_ids;

    $.each( this.userIds, function( i, val ) {
      $.getScript(`/user_profiles/${val}/giving`);
    });
  }

  resetLoaders() {
    // Occasionally, this class gets initialized
    // on a remote render of ajaxy loading content
    // so we add :not(.loaded) to ensure we don't re-trigger
    // already loaded content... back to its "loading" state
    $('.loader-wrap:not(#activity_feed):not(.loaded)').each(function() {
      new Loader($(this)).reset();
    });
  }
};

$(document).on('turbolinks:load', function() {
  if (!($('body.households.show').length > 0)) { return; }
  new HouseholdTransactions;
});


