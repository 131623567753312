class CustomForm {
  constructor(form) {
    this.form = form;

    this.selectsWithFreeformOtherOption = this.form.find('.includes-freeform-other-option select');
    this.selectsWithFreeformOtherOption.on('change', event => {
      this.toggleSelectFreeformOtherOption($(event.target));
    });
    this.selectsWithFreeformOtherOption.each((index, select) => {
      this.toggleSelectFreeformOtherOption($(select));
    });

    this.checkablesWithFreeformOtherOption = this.form.find('.includes-freeform-other-option').find(':checkbox, :radio');
    this.checkablesWithFreeformOtherOption.on('click', event => {
      this.toggleCheckableFreeformOtherOption($(event.target));
    });

    this.checkablesWithFreeformOtherOption.each((index, checkable) => {
      this.toggleCheckableFreeformOtherOption($(checkable));
    });

    this.submitBtn = this.form.find('input[type="submit"]');

    this.form.find('a.remove-file').on('click', event => {
      $(event.currentTarget).closest('.existing-file-container').addClass('d-none')
      var fileInput = this.form.find(`input[type="file"][name="${$(event.currentTarget).data('field-name')}"]`);
      fileInput.before(`<input type="hidden" name="${$(event.currentTarget).data('field-name')}" value="remove"/>`);
      event.preventDefault();
    });
  }

  toggleSelectFreeformOtherOption(select) {
    const freeformOtherOption = select.siblings('.freeform-other-option');
    if (select.val() === 'Other') {
      freeformOtherOption.removeClass('d-none');
    } else {
      freeformOtherOption.addClass('d-none');
    }
  }

  toggleCheckableFreeformOtherOption(checkable) {
    const field = checkable.closest('.includes-freeform-other-option');
    const freeformOtherOption = field.find('.freeform-other-option');

    if (field.find(':input[value="Other"]').is(':checked')) {
      freeformOtherOption.removeClass('d-none');
    } else {
      freeformOtherOption.addClass('d-none');
    }
  }
};

$(document).on('turbolinks:load', function() {
  const customForms = $('.custom, #extended-profile');
  if (!(customForms.length > 0)) { return; }
  customForms.each(function(index, form) {
    new CustomForm($(form));
  });
});
