// Detect IE11 and add a unique class to the html element like modernizr

// Note: the only official ie11 "unique" attribute is "msTextCombineHorizontal", however
// Targeting IE11 with a media query for "msTextCombineHorizontal" will also target IE12+
// Thus, there isn't (as of yet) a way to target IE11 only through media queries.

import Bowser from 'bowser'

$(document).on('turbolinks:load', function() {
  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
  if (browser.name == 'Internet Explorer' && (browser.version === '11.0')) {
    $("html").addClass("no-maxwidth");
  }
});
