class CustomDateRangeModal {
  constructor(modal) {
    this.modal = modal;
    this.form = this.modal.find('form');

    $('body').on('click', '.conditions p.custom-date-range a', event => {
      this.prefill($(event.target));
    });

    this.form.find('#specific_date_range input').on('dp.change', e => {
      this.clearRelativeDateRangeSelects();
    });

    this.form.on('submit', event => {
      return this.handleFormSubmission();
    });
  }

  open() {
    this.modal.modal('open');
  }

  reset() {
    this.form[0].reset();
  }

  prefill(link) {
    const ransackArgsHiddenInput = link.closest('.attribute').find('input:hidden');
    const existingRansackArgs = ransackArgsHiddenInput.val();

    if (existingRansackArgs !== '') {
      const matches = ransackArgsHiddenInput.val().match(/^last (\d+) (day|week|month|year)/);
      if (matches) {
        this.form.find('#number').val(matches[1]);
        this.form.find('#interval').val(matches[2]);
      } else {
        this.form.find('#number').val('');
        this.form.find('#interval').val('');

        const dates = JSON.parse(existingRansackArgs);
        const from = moment(dates[0]);
        this.form.find('#from').val(from.format('YYYY-MM-DD'));
        const to = moment(dates[1]);
        this.form.find('#to').val(to.format('YYYY-MM-DD'));
      }
    } else {
      this.reset();
    }

    this.form.find('#ransacker_ars_id').val(ransackArgsHiddenInput.attr('id'));
  }

  clearRelativeDateRangeSelects() {
    this.form.find('#relative_date_range select').val('');
  }

  handleFormSubmission() {
    let dateRangeText, ransackerArgs;
    const numberField = this.form.find('#number');
    const intervalField = this.form.find('#interval');

    if ((numberField.val() !== '') && (intervalField.val() !== '')) {
      let dateRange = `last ${numberField.val()} ${intervalField.val()}`;
      if (numberField.val() !== '1') { dateRange += 's'; }
      ransackerArgs = dateRange;
      dateRangeText = dateRange;
    } else {
      const fromDate = this.parseDate('from');
      const toDate = this.parseDate('to');
      ransackerArgs = JSON.stringify([fromDate, toDate]);
      dateRangeText = `${this.formatDate(fromDate)} - ${this.formatDate(toDate)}`;
    }

    const ransackerArgsHiddenField = $(`#${this.form.find('#ransacker_ars_id').val()}`);
    ransackerArgsHiddenField.val(ransackerArgs);

    const attribute = ransackerArgsHiddenField.closest('.attribute');
    attribute.find('.custom-date-range').remove();
    attribute.append(`<p class=\"custom-date-range form-text text-muted mb-0\">${dateRangeText} <a href=\"#\" data-toggle=\"modal\" data-target=\"#custom_date_range_modal\">Edit</a></p>`);

    this.modal.modal('hide');
    return false;
  }

  parseDate(name) {
    return moment(this.form.find(`#${name}`).val());
  }

  formatDate(date) {
    return date.format('LL');
  }
};

$(document).on('turbolinks:load', function() {
  const modal = $('#custom_date_range_modal');
  if (!(modal.length > 0)) { return; }
  new CustomDateRangeModal(modal);
});
