class FormFieldForm {
  constructor(form) {
    this.form = form;
    this.fieldTypeSelect = this.form.find('#form_field_field_type');
    this.optionFieldTypes = ["select", "checkboxes", "radio_buttons"];
    this.placeholderFieldTypes = ["textbox", "textarea", "select", "email_address", "phone_number", "date", "number", "us_state"];
    this.optionFields = this.form.find('#option-fields');
    this.placeholderField = this.form.find('#placeholder-field');
    this.htmlContentField = this.form.find('#html-content-field');
    this.nonHtmlFields = this.form.find('#non-html-fields');
    this.addOptionLink = this.form.find('a.add-option');
    this.addOptionLink.on('click', event => {
      this.addOption();
      event.preventDefault();
    });
    this.options = this.form.find('#options');

    this.fieldTypeSelect.on('change', () => this.handleFieldTypeSelectChange());
    this.form.on('submit', () => this.insertOptionPositions());

    this.handleFieldTypeSelectChange();
    this.initSortable();

    this.options.on('click', 'a.remove', (e) => {
      if (confirm('Are you sure you want to remove this option?')) {
        let option = $(e.currentTarget).closest('.option');
        this.removeOption(option);
      }
    });
  }

  removeOption(option) {
    // Persisted options are inactivated, while new options are removed from the DOM.
    let inactiveInput = option.find('input[name$="[inactive]"]');
    if (inactiveInput.length) {
      inactiveInput.val('true');
      option.addClass('d-none');
      let nameInput = option.find('input[name$="[name]"]');
      // Resetting the option's name to its default value prevents validation errors
      // if the name is cleared before it's removed.
      nameInput.val(nameInput[0].defaultValue);
    } else {
      option.remove();
    }
  }

  insertOptionPositions() {
    this.options.find('.option').each(function(index, option) {
      const name = $(option).find('input[type="text"]:first').attr('name').replace('[name]', '[position]');
      $(option).find(`input:hidden[name=\"${name}\"]`).remove();
      $(option).append(`<input type=\"hidden\" name=\"${name}\" value=\"${(index + 1)}\"/>`);
    });
  }

  initSortable() {
    this.options.sortable({
      axis: 'y',
      handle: 'a.sort'
    });
  }

  handleFieldTypeSelectChange() {
    const fieldType = this.fieldTypeSelect.val();

    if ($.inArray(fieldType, this.optionFieldTypes) !== -1) {
      this.optionFields.removeClass('d-none');
    } else {
      this.optionFields.addClass('d-none');
    }

    if ($.inArray(fieldType, this.placeholderFieldTypes) !== -1) {
      this.placeholderField.removeClass('d-none');
    } else {
      this.placeholderField.addClass('d-none');
    }

    if (fieldType === 'html') {
      this.htmlContentField.removeClass('d-none');
      this.nonHtmlFields.addClass('d-none');
    } else {
      this.htmlContentField.addClass('d-none');
      this.nonHtmlFields.removeClass('d-none');
    }
  }

  addOption() {
    const nextIndex = this.optionFields.find('.option').length;
    const html = `<div class=\"option pb-2\">
                    <div class=\"media\">
                      <a href=\"#\" class=\"btn btn-link mr-2 sort\">
                        <i class=\"fa fa-sort text-muted\" aria-hidden=\"true\"></i>
                      </a>
                      <div class=\"media-body\">
                        <input class=\"form-control\" id=\"form_field_options_attributes_${nextIndex}_name\" name=\"form_field[options_attributes][${nextIndex}][name]\" size=\"30\" type=\"text\" />
                      </div>
                      <a class=\"btn btn-link ml-2 remove\" href=\"javascript:;\">
                        <i class=\"fa fa-trash-o text-danger\" aria-hidden=\"true\"></i>
                      </a>
                    </div>
                  </div>`;

    this.options.append(html);
    this.options.sortable('refresh');
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#form-field-form');
  if (!(form.length > 0)) { return; }
  new FormFieldForm(form);
});
