/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Loads a responsive, accurate page preview in an iframe:
//
// 1) Calculates the zoom level of the iframe based on its parent container's relative width
// 2) Uses the calculated zoom level to apply the correct height and width of the iframe element.

// Usage:
// Assign data-toggle='iframe-preview' to the iframe's parent div
// Add the 'fade' css class to the iframe parent container (it's made invisible during load, and then faded in via adding an 'in' class once loaded and resized)

// Example usage:
// .responsive-iframe-container.fade{ data: { toggle: 'iframe-preview' } }
//   %iframe.responsive-iframe{:src => project_path(project, iframe_preview: 'true') }

class IframePreview {
  constructor(el) {
    this.el = el;
    this.iframe = this.el.find('iframe');
    this.zoom = (this.el.width() / $(document).width());
    this.height = 1500; // unfortunately we can't dynamically measure this b/c of cross-origin iframe errors

    this.iframe.on('load', e => {
      this.setPreviewAndWidthCSS();
      return setTimeout(() => {
        this.setPreviewHeight();
        return this.el.addClass('in');
      }
      , 250);
    }); //allows height measurement to occur *after* the responsive images have been resized.
  }

  setPreviewHeight() {
    // const { body } = this.iframe[0].contentDocument;
    // const html = this.iframe[0].contentDocument.documentElement;

    // this.iframe[0].height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    // return this.el.height(body.scrollHeight * this.zoom);

    this.iframe[0].height = this.height;
    return this.el.height(this.height * this.zoom);
  }

  setPreviewAndWidthCSS() {
    this.iframe[0].width = document.body.scrollWidth;

    if ($('html.no-smil:not(.no-flexboxtweener)').length) { // IE10 & 11
      return this.iframe[0].style.zoom = this.zoom;
    } else { // everything else
      return this.iframe[0].style.transform = `scale(${this.zoom})`;
    }
  }
}

$(document).on('turbolinks:load', function() {
  const el = $("[data-toggle='iframe-preview']");

  if (!el.length) { return; }
  return el.each(function() {
    let inst;
    return inst = new IframePreview($(this));
  });
});
