import accounting from 'accounting'
import Chart from 'chart.js/auto'

class TransactionsChart {
  constructor(chart) {
    this.chart = chart;

    const ctx = this.chart.get(0).getContext('2d');
    const values = this.chart.data('values');
    const datasets = [];

    if (values.ticket_purchases) {
      datasets.push({
        label: 'Ticket Revenue',
        backgroundColor: "rgba(255,170,0,.2)",
        borderColor: "rgba(255,170,0,1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(255,170,0,1)",
        data: values.ticket_purchases,
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
        fill: true
      });
    }

    if (values.donations) {
      datasets.push({
        label: 'Donations',
        backgroundColor: "rgba(89,191,34,.2)",
        borderColor: "rgba(89,191,34,1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(89,191,34,1)",
        data: values.donations,
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
        fill: true
      });
    }

    if (values.total) {
      datasets.push({
        label: 'Total',
        backgroundColor: "rgba(37,172,235,.2)",
        borderColor: "rgba(37,172,235,1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(37,172,235,1)",
        data: values.total,
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
        fill: true
      });
    }

    if (values.soft_credits) {
      datasets.push({
        label: 'Soft Credits',
        backgroundColor: "rgba(154, 154, 154,.2)",
        borderColor: "rgba(154, 154, 154, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(154, 154, 154, 1)",
        data: values.soft_credits,
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
        fill: true
      });
    }

    if (values.sponsorship_purchases) {
      datasets.push({
        label: 'Sponsorship Revenue',
        backgroundColor: "rgba(49, 58, 62,.2)",
        borderColor: "rgba(49, 58, 62, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(49, 58, 62, 1)",
        data: values.sponsorship_purchases,
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
        fill: true
      });
    }

    if (values.auction_item_purchases) {
      datasets.push({
        label: 'Auctions Revenue',
        backgroundColor: "rgb(112, 120, 124, .2)",
        borderColor: "rgb(112, 120, 124, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgb(112, 120, 124, 1)",
        data: values.auction_item_purchases,
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
        fill: true
      });
    }

    const data = {
      labels: this.chart.data('keys'),
      datasets
    };
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        interaction: {
          intersect: false,
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            label(tooltipItem, data) {
              const datasetLabel = data.datasets[tooltipItem.datasetIndex].label;
              return `${datasetLabel}: ${accounting.formatMoney(tooltipItem.yLabel, { precision: 0 })}`;
            }
          }
        }
      },
      scales: {
        y: {
          ticks: {
            callback(tickValue, index, ticks) {
              return accounting.formatMoney(tickValue, { precision: 0 });
            }
          },
          beginAtZero: true
        }
      }
    };
    const lineChart = new Chart(ctx, {
      type: 'line',
      data,
      options
    });
  }
};

$(document).on('turbolinks:load', function() {
  const chart = $('#transactions_chart');
  if (!(chart.length > 0)) { return; }
  new TransactionsChart(chart);
});
