import accounting from 'accounting'
import Chart from 'chart.js/auto'

export default class DonationsChart {
  constructor(chart) {
    this.chart = chart;
    const ctx = this.chart.get(0).getContext('2d');
    const data = {
      labels: this.chart.data('keys'),
      datasets: [{
        backgroundColor: "rgba(89,191,34,0.2)",
        borderColor: "rgba(89,191,34,1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(89,191,34,1)",
        data: this.chart.data('values'),
        cubicInterpolationMode: 'monotone',
        fill: true,
        tension: 0.4
      }]
    };
    const options = {
      plugins: {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label(tooltip) {
              return `Donations: ${accounting.formatMoney(tooltip.yLabel, { precision: 0 })}`;
            }
          }
        }
      },
      scales: {
        y: {
          ticks: {
            callback(tickValue, index, ticks) {
              return accounting.formatMoney(tickValue, { precision: 0 });
            },
            beginAtZero: true
          }
        }
      }
    };
    const lineChart = new Chart(ctx, {
      type: 'line',
      data,
      options
    });
  }
};

$(document).on('turbolinks:load', function() {
  const chart = $('#donations-chart');
  if (!(chart.length > 0)) { return; }
  new DonationsChart(chart);
});
