class DonationForm {
  constructor(form) {
    this.form = form;
    this.tributeFields = this.form.find('#tribute_fields');
    this.tributeType = this.form.find('#donation_tribute_attributes_tribute_type');
    this.tributeType.change(event => {
      this.toggleTributeFields($(event.target));
    });
    this.toggleTributeFields(this.tributeType);

    this.tributeNotificationFields = this.form.find('#tribute_notification_fields');
    this.tributeNotificationMethod = this.form.find('#donation_tribute_attributes_notification_method');
    this.tributeNotificationMethod.change(event => {
      this.toggleTributeNotificationFields($(event.target));
    });
    this.toggleTributeNotificationFields(this.tributeNotificationMethod);

    this.quickAddModal = $('#quick_add_user_modal');
    this.form.on('click', '[data-target="#quick_add_user_modal"]', event => {
      const fieldId = $(event.target).closest('.form-group').find('select.user-select').attr('id');
      this.quickAddModal.find('input[name="user_select_field_id"]').val(fieldId);
    });

    if (this.form.find('#unpaid_pledges').length) {
      this.loadPledges();
      this.form.find('#donation_user_id').change(event => {
        this.loadPledges();
      });
    }
  }

  toggleTributeFields(select) {
    if (!(select.length > 0)) { return; }
    if (select.val().toLowerCase() === 'no') {
      this.tributeFields.addClass('d-none');
    } else {
      this.tributeFields.removeClass('d-none');
    }
  }

  toggleTributeNotificationFields(select) {
    if (!(select.length > 0)) { return; }
    if (select.val().toLowerCase() === 'none') {
      this.tributeNotificationFields.addClass('d-none');
    } else {
      this.tributeNotificationFields.removeClass('d-none');
    }
  }

  loadPledges() {
    const userId = this.form.find('#donation_user_id').val();
    if (!!userId) {
      $.getScript(`/users/${userId}/pledges?status=unpaid`);
    } else {
      this.form.find('#unpaid_pledges').empty();
    }
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#donation-form');
  if (!(form.length > 0)) { return; }
  new DonationForm(form);
});
