$(document).on('click', '.modal-footer a.submit', function(event) {
  var form = $(this).closest('.modal').find('form');
  if (form.data('remote'))
    Rails.fire(form[0], 'submit');
  else
    form.submit();
  event.preventDefault();
});

$(document).on('shown.bs.modal', function(e) {
  const modal = $(e.target);
  if (!modal.hasClass('no-auto-focus')) {
    modal.find(':text, textarea').first().focus();
  }
});
