import Mousetrap from 'mousetrap'

$(document).on('turbolinks:load', () => handleKeyBindings());

var handleKeyBindings = function() {
  // As turbolinks does not refresh the page, some old keybindings could be still present. Therefore a reset is required.
  Mousetrap.reset();

  // Hotkey binding to links with 'data-keybinding' attribute
  // Navigate link when hotkey pressed
  $('a[data-keybinding]').each(function(i, el) {
    let bindedKey = $(el).data('keybinding');
    if (typeof(bindedKey) === 'number') { bindedKey = bindedKey.toString(); }
    Mousetrap.bind(bindedKey, function(e) {
      if (typeof(Turbolinks) === 'undefined') {
        // Emulate click if turbolinks defined
        el.click();
      } else {
        // Use turbolinks to go to URL
        Turbolinks.visit(el.href);
      }
    });
  });

  // Hotkey binding to inputs with 'data-keybinding' attribute
  // Focus input when hotkey pressed
  $('input[data-keybinding]').each((i, el) =>
    Mousetrap.bind($(el).data('keybinding'), function(e) {
      el.focus();
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      }
    })
  );

  // Toggle show/hide hotkey hints
  window.mouseTrapRails = {
    showOnLoad: false,           // Show/hide hotkey hints by default (on page load). Mostly for debugging purposes.
    toggleKeys: 'alt+shift+h',   // Keys combo to toggle hints visibility.
    keysShown: false,            // State of hotkey hints
    toggleHints() {
      $('a[data-keybinding]').each(function(i, el) {
        const $el = $(el);
        if (mouseTrapRails.keysShown) {
          $el.removeClass('mt-hotkey-el').find('.mt-hotkey-hint').remove();
        } else {
          const mtKey = $el.data('keybinding');
          const $hint = `<i class='mt-hotkey-hint' title='Press \<${mtKey}\> to open link'>${mtKey}</i>`;
          if ($el.css('position') !== 'absolute') { $el.addClass('mt-hotkey-el'); }
          $el.append($hint);
        }
      });
      this.keysShown ^= true;
    }
  };

  Mousetrap.bind(mouseTrapRails.toggleKeys, () => mouseTrapRails.toggleHints());

  if (mouseTrapRails.showOnLoad) { mouseTrapRails.toggleHints(); }

  Mousetrap.bind('a', () => $("#quick-add-modal").modal());

  Mousetrap.bind('s', () => $("#quick_search_modal").modal());

  // When on the users/show view, "t" hotkey will load
  // the user's modal which has their name pre-filled
  if ($('body').hasClass('users show')) {
    Mousetrap.bind('t', () => $("#add_task_via_hotkey_from_profile_page").click());
  } else {
    Mousetrap.bind('t', () => $("#go_to_add_task_modal_via_quick_add_modal").click());
  }
};
