import Handlebars from 'handlebars'

export default class AutoComplete {
  constructor(field) {
    this.field = field;
    this.form = this.field.closest('form');
    this.displayKey = this.field.data('typeahead-display-key') || 'name';
    this.searchUrl = this.field.data('typeahead-search-url') || `${this.form.attr('action')}.json?${this.field.attr('name')}=%QUERY`;
    this.selectedUrl = this.field.data('typeahead-selected-url');
    this.emptyTemplate = this.field.data('typeahead-empty-template') || "";
    this.suggestionTemplate = this.field.data('typeahead-suggestion-template') || `{{${this.displayKey}}}`;
    this.initBloodhound();
    this.initTypeahead();
  }

  initBloodhound() {
    this.bloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: this.searchUrl,
        wildcard: '%QUERY'
      }
    });
    this.bloodhound.initialize();
  }

  destroyTypeahead() {
    this.field.typeahead('destroy');
  }

  initTypeahead() {
    this.field.typeahead(
      {
        hint: false,
        highlight: true,
        minLength: 2
      },
      {
        // DM-2330 We set the limit to Infinity to prevent issues where not all
        // results are displayed if less than limit.
        // See https://github.com/twitter/typeahead.js/issues/1232 for details
        limit: Infinity,
        name: this.field.id,
        displayKey: this.displayKey,
        source: this.bloodhound.ttAdapter(),
        templates: {
          empty: `<div class="tt-empty-template"><p class="mb-1">There are no results.</p><p class="mb-0">${this.emptyTemplate}</p></div>`,
          suggestion: Handlebars.compile(`<div data-heap-redact-text="true">${this.suggestionTemplate}</div>`)
        }
      }
    ).on('typeahead:selected', (e, result) => {
      if (this.selectedUrl) {
        window.location = Handlebars.compile(this.selectedUrl)(result);
      } else {
        this.form.submit();
      }
    });
  }
};

$(document).on('turbolinks:load', function() {
  const fields = $(':input.typeahead-search');
  if (!(fields.length > 0)) { return; }
  fields.each(function() {
    new AutoComplete($(this));
  });
});
