class MailingForm {
  constructor(form) {
    this.form = form;
    this.layoutTypeRadioButtons = this.form.find('input[name="mailing[layout_type]"]');
    this.mailingFields = this.form.find('#mailing_fields');
    this.templateField = this.form.find('#template_field');
    this.labelTypeSelect = this.form.find('select[name="mailing[label_type]"]');
    this.labelFields = this.form.find('#label_fields');

    this.labelTypeSelect.change(() => {
      this.toggleLabelFields();
    });
    this.toggleLabelFields();

    this.layoutTypeRadioButtons.click(() => {
      this.switchLayoutType();
    });
    this.switchLayoutType();
  }

  switchLayoutType() {
    if (this.layoutTypeRadioButtons.eq(0).is(':checked')) {
      this.templateField.removeClass('d-none');
      this.mailingFields.addClass('d-none');
    } else if (this.layoutTypeRadioButtons.eq(1).is(':checked')) {
      this.templateField.addClass('d-none');
      this.mailingFields.removeClass('d-none');
    }
  }

  toggleLabelFields() {
    if (!!this.labelTypeSelect.val()) {
      this.labelFields.removeClass('d-none');
    } else {
      this.labelFields.addClass('d-none');
    }
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#mailing_form');
  if (!(form.length > 0)) { return; }
  new MailingForm(form);
});
