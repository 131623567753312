// Keeps dropdown menu open when clicking inside
$(document).on('click', '.dropdown-menu[data-always-open="true"]', e => {
  e.stopPropagation();
});

// Keeps dropdown menu open in the background when a modal is also open
// This comes into play when filtering on the QuickBooks transaction list screen
$(document).on('turbolinks:load', function() {
  $('.dropdown-menu[data-always-open="true"]').parent().on('hide.bs.dropdown', e => {
    if ($('body').hasClass('modal-open'))
      e.preventDefault();
  });
});