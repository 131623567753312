class OnboardingBuildForm {
  constructor(form) {
    let proceed;
    this.form = form;
    this.confirmation = 'Did you forget to click the Save button?  You have unsaved changes that will be lost if you leave this page.';
    this.saveAndExitLink = $('a#save_and_exit');

    $(window).on('beforeunload', () => {
      if (this.hasUnsavedChanges()) {
        return this.confirmation;
      }
    });

    $(document).on('turbolinks:before-visit', e => {
      if (this.hasUnsavedChanges()) {
        proceed = confirm(this.confirmation);
        if (!proceed) { e.preventDefault(); }
      }
    });

    // When there are unsaved changes in the editor and user clicks on any other links,
    // then we want to save the changes and redirect to the currosponding step instead of
    // proceeding to the setup step. We are passing a hidden param as jump_to_step to the form
    // when there are unsaved changes. If there are no unsaved changes, then we proceed to next
    $('a#edit_name, a#prev_step, a.nav-link').on('click', event => {
      if (window.bee.hasChanges) {
        let value = this.targetValue(event);

        $('<input>', {
          type: 'hidden',
          name: 'jump_to_step',
          value: value
        }).appendTo('form'); // append to form

        Rails.disableElement(this.form[0]); // disable form
        window.bee.save(); // save changes
        return false;
      }
    });

    this.form.on('submit', e => {
      this.enableSaveAndExitLink();

      if (window.bee) {
        // Calling window.bee.save() then returning true works fine locally, but
        // on beta, this didn't give the BEE save call enough time to complete and
        // invoke the callback in bee.js - which sends the html/json to our server.
        // This is my best attempt at givng that save call some extra time to finish
        // before we let the form submit.
        Rails.disableElement(this.form[0]);
        window.bee.save();
        return false;
      } else {
        if (this.hasUnsavedChanges()) {
          proceed = confirm(this.confirmation);

          if (proceed) {
            // This prevents admins from being prompted a second time about
            // unsaved changes by the beforeunload event defined near the top of
            // this file.
            $(window).off('beforeunload');
          }
          else {
            this.disableSaveAndExitLink();
            e.stopImmediatePropagation(); // So no other submit events fire for this form
          }

          return proceed;
        }
      }
    });
  }

  hasUnsavedChanges() {
    const iframe = $('iframe#email_editor, iframe#mailing_editor');
    if (!iframe.length) { return; }
    const iframeWindow = iframe[0].contentWindow;
    return iframeWindow.App.contentSectionsInst.hasUnsavedChanges();
  }

  enableSaveAndExitLink() {
    this.saveAndExitLink.addClass('disabled');
  }

  disableSaveAndExitLink() {
    this.saveAndExitLink.removeClass('disabled');
  }

  targetValue(event) {
    switch (event.target.id) {
      case 'edit_name':
        return 'introduction'
      case 'prev_step':
        return 'layout'
      default: // nav link
        return event.target.parentElement.href.split('/').pop()
    }
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('body.onboarding-email_creator.build, body.onboarding-mailing_creator.build, body.email_gallery_drafts').find('form#onboarding_main_form');
  if (!(form.length > 0)) { return; }
  new OnboardingBuildForm(form);
});
