class EmbeddedFormModal {
  constructor(modal) {
    this.modal = modal;
    this.embedLinks = $('a[data-target="#embedded-form-modal"]');
    this.embedLinks.click(event => {
      this.setFormId($(event.target));
    });
    this.colorInput = this.modal.find('input[name="color"]');
    this.colorInput.blur(() => {
      this.setCodeSnippet();
    });
    this.codeSnippetInput = this.modal.find('input[name="code_snippet"]');
  }

  setFormId(link) {
    this.formId = link.closest('.slat').data('id');
    this.setCodeSnippet();
  }

  setCodeSnippet() {
    let url = window.location.protocol + '//' + window.location.host + '/forms/' + this.formId + '?iframe=1';

    const formGroup = this.colorInput.closest('.form-group');
    // This needs to be changed from error to has-danger in bs4
    formGroup.removeClass('error');
    const color = this.colorInput.val().replace('#', '');
    if (color.match(/^([0-9a-f]{3}|[0-9a-f]{6})$/i)) {
      url += `&color=${color}`;
    } else if (color !== '') {
      formGroup.addClass('error');
    }

    this.codeSnippetInput.val(`<iframe src="${url}" width="450" height="550" frameborder="0"></iframe>`);
  }
};

$(document).on('turbolinks:load', function() {
  const modal = $('#embedded-form-modal');
  if (!(modal.length > 0)) { return; }
  new EmbeddedFormModal(modal);
});
