class IframeHeight {
  constructor(iframe) {
    this.iframe = iframe;

    this.iframe.on('load', e => {
      this.iframe[0].style.height = (this.iframe[0].contentDocument.body.scrollHeight * 1.1) + "px";
    }); //allows height measurement to occur *after* the responsive images have been resized.
  }
}

$(document).on('turbolinks:load', function() {
  const iframes = $("iframe[data-describe='resize-height']");

  if (!iframes.length) { return; }
  return iframes.each(function() {
    let inst;
    return inst = new IframeHeight($(this));
  });
});
