// Here's an example of what the haml looks like...
// .doughnut-chart
//   = content_tag :canvas, nil, { id: 'donor-chart', data: { values: @presenter.donor_chart_values }}

import accounting from 'accounting'
import Chart from 'chart.js/auto'

export default class DoughnutChart {
  constructor(chart) {
    this.chart = chart;
    const ctx = this.chart.get(0).getContext('2d');

    // Without these two lines, the doughnut charts only take up half the
    // available width.  I'm not sure why at this point.
    ctx.canvas.width = 300;
    ctx.canvas.height = 300;

    // data-values needs to be an array of hashs that looks something like...
    // data = [
    //   {
    //     value: 10
    //     color: '#f8653a'
    //     label: 'Lapsed Donors'
    //   },
    //   {
    //     value: 50
    //     color: '#d9d9d9'
    //     label: 'Active Donors'
    //   }
    // ]

    let data = this.chart.data('values');
    data = this.convertColorsToHex(data);
    data = this.convertDataToV2(data);

    const options = {
      plugins: {
        legend: {
          display: false
        },

        tooltips: {
          callbacks: {
            label: (tooltip, data) => {
              const i = tooltip.index;
              let label = data.labels[i];
              let value = data.datasets[0].data[i];
              if (this.chart.data('format') === 'currency') {
                value = accounting.formatMoney(value, { precision: 0 });
              }

              // Returning an array displays the items on their own line.  We need to do
              // this for long labels ot prevent tooltips from being cutoff at the edge
              // of the chart.
              if (label.length > 15) {
                label = `${label.substring(0, 20)}...`;
                return [label, value];
              } else {
                return `${label}: ${value}`;
              }
            }
          }
        }
      },
    };

    // Use data-format="currency" to prepend the value with "$" in the tooltip
    if (this.chart.data('format') === 'currency') {
      options.tooltipTemplate = "\<\%if(label) {\%\>\<\%=label\%\>:\n\<\%}\%\>$\<\%= value \%\>";
    }

    this.chartJsInst = new Chart(ctx, {
      type: 'doughnut',
      data,
      options
    });
  }

  // Allows you to pass in our supported colors by name instead of hex
  convertColorsToHex(data) {
    const lookup = {
      blue: '#25ACEB',
      green: '#59BF22',
      orange: '#FFAA00',
      yellow: '#ffd500',
      red: '#FF6D33',
      grey: '#C9CED1'
    };

    $.each(data, function(index, hash) {
      if (!!lookup[hash.color]) {
        return hash.color = lookup[hash.color];
      }
    });

    return data;
  }

  // Chart.js v2 changed the structure for the data passed into their pie/doughnut
  // charts.  This function handles the conversion from v1 to v2, which prevents us
  // from updating the data we're passing in in severa of the views/presenters.
  convertDataToV2(data) {
    const newData = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: []
      }]
    };

    $.each(data, function(index, hash) {
      newData.labels.push(hash.label);
      newData.datasets[0].data.push(hash.value);
      newData.datasets[0].backgroundColor.push(hash.color);
      newData.datasets[0].hoverBackgroundColor.push(hash.color);
    });

    return newData;
  }
};

$(document).on('turbolinks:load', function() {
  const charts = $('.doughnut-chart canvas');
  if (!(charts.length > 0)) { return; }
  charts.each(function() {
    new DoughnutChart($(this));
  });
});
