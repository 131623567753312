import ahoy from 'ahoy'

class VideoDonateModal {
  constructor(donateModal) {
    this.donateModal = donateModal;

    this.donateModal.on('shown.bs.modal', e => {
      ahoy.track('Viewed donate nudge modal', { id: this.donateModal.attr('id'), page: window.location.pathname });
    });
  }
};

$(document).on('turbolinks:load', function() {
  const donateModal = $('#video_view_donate_nudge_modal');
  if (!(donateModal.length > 0)) { return; }
  new VideoDonateModal(donateModal);
});