class CampaignDashboard {
  constructor() {
    this.viewTopResponsesBtn = $('a[data-describe="view-custom-fields-responses-link"]');

    if (!this.viewTopResponsesBtn.hasClass('disabled')) {
      this.viewTopResponsesBtn.on('click', event => {
        this.toggleViewTopResponsesBtnText();
      });
    }
  }

  toggleViewTopResponsesBtnText() {
    if (this.viewTopResponsesBtn.text() == 'View Top Responses')
      this.viewTopResponsesBtn.text('Hide Top Responses');
    else
      this.viewTopResponsesBtn.text('View Top Responses');
  }
};

$(document).on('turbolinks:load', function() {
  const el = $('body.campaigns.show');
  if (!(el.length > 0)) { return; }
  new CampaignDashboard();
});
