import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["title", "customTitle"]

  toggleCustomTitle() {
    if (this.titleTarget.value === 'other') {
      this.customTitleTarget.classList.remove('d-none')
    } else {
      this.customTitleTarget.classList.add('d-none')
    }
  }
}