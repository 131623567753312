export default class ContactList {
  constructor() {
    const firstContactThatIsEmailableOrTextable = $('[data-emailable-or-textable]').first();
    if (firstContactThatIsEmailableOrTextable.length) {
      firstContactThatIsEmailableOrTextable.addClass('first-emailable-or-textable-contact');
    }
  }
};

$(document).on('turbolinks:load', function() {
  if (!($('body.users.index').length > 0)) { return; }
  new ContactList();
});
