// Places an icon near an element, navigation, field, etc, that indicates
// that that item is available only for sys users.

// Within the application, we can add a data-describe attribute to those
// elements. This js will look for that attribute and prepend to the
// container's body html to display the icon.

// if we want to change the icon, we can simply change the html that is
// injected into the page.

export default class SysFunctionalityIndicator {
  constructor() {
    this.bindOnLoad();
  }

  bindOnLoad()  {
    // Since we can't always rely on adding the sys_only_functionality data
    // describe attribute, we have to rely on a list of matchers
    const sysFunctionCssFinders = this.cssFinders().join(", ");
    const sysFunctionElems = $(sysFunctionCssFinders);
    if (!(sysFunctionElems.length > 0)) { return; }
    sysFunctionElems.each(function(i,el) {
      this.prependSysOnlyIndicatorIntoContainer($(el));
    }.bind(this));
  }

  prependSysOnlyIndicatorIntoContainer(el) {
    // in cases where this may be run twice (i.e on page and turbolinks load), check if the notification icon is already there
    if(el.find('.notify').length) return;

    el.prepend("<div class='notify' title='NFG SysAdmin/SysSupport functionality'><span class='heartbit'></span><span class='point'></span></div>");
  }

  cssFinders() {
    return [
      '[data-describe="sys_only_functionality"]',
      '[data-describe="individual_donation_with_groups_file_origination_type"] .custom-control-label',
      '[data-describe="ncoa_data_services_bulk_importer_file_origination_type"] .custom-control-label',
      '[data-describe="update_donation_import_type"] .custom-control-label',
      '[data-describe="custom_field_import_type"] .custom-control-label',
      '[data-describe="soft_credits_import_type"] .custom-control-label',
      '[data-describe="pledges_import_type"] .custom-control-label',
      '[data-describe="relationships_import_type"] .custom-control-label',
      '[data-describe="individual_auction_item_purchase_import_type"] .custom-control-label',
      '[data-describe="remove_details_import_type"] .custom-control-label',
      '#imports_listing .slat[data-status="complete"] a.dropdown-item.text-danger',
      'form[action="/imports/onboarding/import_data/import_type"] .tile-body'
    ];
  }
};

window.SysFunctionalityIndicator = SysFunctionalityIndicator;

// In some instances, such as in the importer,
// turbolinks is not included, so we don't want to
// rely on it being available.  With the below we will
// use Turbolinks events to fire the javascript but if
// it is not available, we just use the regular
// window load.
$(window).on("load turbolinks:load", function(e) {
  new SysFunctionalityIndicator()
});
