import AutoComplete from './auto_complete';

class QuickSearchModal {
  constructor(modal) {
    this.modal = modal;
    this.form = this.modal.find('form');
    this.results = this.modal.find('#quick_search_results');

    $('a[data-target="#quick_search_modal"]').click(() => {
      this.reset();
    });
  }

  reset() {
    // We destroy and re-init typeahead to clean up all the extra markup that
    // gets added - like the dropdown menu of results.
    const typeaheadInput = this.modal.find(':input.typeahead-search');
    const typeaheadInst = new AutoComplete(typeaheadInput);
    typeaheadInst.destroyTypeahead();

    this.form[0].reset();
    this.results.empty();

    typeaheadInst.initTypeahead();
  }
};

$(document).on('turbolinks:load', function() {
  const modal = $('#quick_search_modal');
  if (!(modal.length > 0)) { return; }
  new QuickSearchModal(modal);
});
