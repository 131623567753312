import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["options"];

  listPhoneNumbers(event) {
    const areaCode = event.target.value; // get the value of the area code input

    if(areaCode.length >= 3) {
      // fetch the data from the server and then update the select box with the new options
      fetch(`/twilio_numbers?area_code=` + areaCode) // make an AJAX request to the server
      .then(response => response.json()) // parse the response as JSON
      .then((data) => { // "data" is the parsed JSON object returned from the above `response.json()` call
        const targetElement = this.optionsTarget;

        const messageParagraph = targetElement.nextElementSibling;

        if (messageParagraph && messageParagraph.tagName === "P") {
          messageParagraph.remove(); // remove the message paragraph if it exists
        }

        targetElement.innerHTML = ""; // clear out the old options

        const defaultOption = document.createElement("option");
        defaultOption.value = ""; // Set the value of the default option, modify as needed
        defaultOption.textContent = "Choose a phone number from the list"; // Text for the default option, modify as needed
        targetElement.appendChild(defaultOption);

        if(data.phone_numbers.length === 0) {
          const messageParagraph = document.createElement("p");
          messageParagraph.textContent = "No phone numbers available for the area code : " + areaCode + ". Please try another area code.";
          messageParagraph.classList.add("form-text", "mb-0", "text-danger"); // Add classes to the paragraph element

          targetElement.parentNode.insertBefore(messageParagraph, targetElement.nextSibling); // insert the message paragraph after the select box
        } else {
          data.phone_numbers.forEach((option) => { // add the new options
            const optionElement = document.createElement("option"); // create a new option element
            optionElement.value = option.value; // the value that gets submitted
            optionElement.textContent = option.text; // the text that is shown to the user
            targetElement.appendChild(optionElement); // add the option to the select box
          });
        }
      })
      .catch(error => {
        const messageParagraph = document.createElement("p");
        messageParagraph.textContent = error;
        messageParagraph.classList.add("form-text", "mb-0", "text-danger"); // Add classes to the paragraph element
      });
    }
  }
}
