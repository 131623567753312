class UserEmailForm {
  constructor(form) {
    this.form = form;
    this.subjectTextbox = this.form.find('#user_email_subject');
    this.subjectPreview = $('#user_email_subject_preview');
    this.ccTextbox = this.form.find('#user_email_cc');
    this.ccPreview = $('#user_email_cc_preview');
    this.ccPreviewContainer = $('#user_email_cc_preview_container');
    this.bccTextbox = this.form.find('#user_email_bcc');
    this.bccPreview = $('#user_email_bcc_preview');
    this.bccPreviewContainer = $('#user_email_bcc_preview_container');
    this.subjectTextbox = this.form.find('#user_email_subject');
    this.subjectPreview = $('#user_email_subject_preview');
    this.attachmentsPreview = $('#user_email_attachments_preview');
    this.attachmentsPreviewContainer = $('#user_email_attachments_preview_container');
    this.contentTextarea = this.form.find('#user_email_content');
    const contentPreview = $('#user_email_content_preview');

    this.subjectTextbox.on('keyup', event => {
      this.subjectPreview.html(this.subjectTextbox.val());
    });

    this.ccTextbox.on('keyup', event => {
      let val = this.ccTextbox.val()
      this.ccPreviewContainer.toggleClass('d-none', !val);
      this.ccPreview.html(val);
    });

    this.bccTextbox.on('keyup', event => {
      let val = this.bccTextbox.val()
      this.bccPreviewContainer.toggleClass('d-none', !val);
      this.bccPreview.html(val);
    });

    this.contentTextarea.redactor('core.destroy');
    this.contentTextarea.redactor({
      paragraphize: false,
      linebreaks: true,
      changeCallback: function() {
        contentPreview.html(this.code.get());
      }
    });

    this.form.find('.attachments').on('change', 'input[type="file"]', event => {
      this.attachmentsPreviewContainer.removeClass('d-none');
      let html = this.attachmentsPreview.html();
      if(!!$.trim(html)) { html += ', ' }
      html += event.target.files[0].name
      this.attachmentsPreview.html(html);
    });
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#new_user_email');
  if (!(form.length > 0)) { return; }
  new UserEmailForm(form);
});