$(document).on('turbolinks:load', () =>
  $('.copy-to-clipboard').each(function() {
    $(this).attr('data-original-title', 'Copy to clipboard').tooltip();

    const cb = new Clipboard(this);
    cb.on('success', function(e) {
      let trigger = $(e.trigger);
      trigger.tooltip('dispose').attr('data-original-title', 'Copied!');
      // I don't know why, but without a setTimeout here, the updated "Copied!"
      // tooltip doesn't display unless you move your mouse out and back into the
      // button/link.
      setTimeout(() => trigger.tooltip('show'), 1);
      setTimeout(() => trigger.tooltip('hide').attr('data-original-title', 'Copy to clipboard'), 2000);
    });
  })
);
