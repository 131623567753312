export default class DateTimePicker {
  constructor(input) {
    this.input = input;
    this.input.datetimepicker(this.options());
    this.input[0].setAttribute('data-datetimepicker', 'date');
    this.input[0].readOnly = true;
    this.input.on('dp.show', event => {
      $('.bootstrap-datetimepicker-widget').addClass('fs-ignore-rage-clicks'); // makes fullstory ignore "rage clicks" on the datetimepicker
      this.replaceGlyphiconIcon("left");
      this.replaceGlyphiconIcon("right");
    });
  }

  options() {
    const options = this.input.data('options') || {};
    if (!options.format) { options.format = this.defaultFormat(); }
    options.useCurrent = false;
    options.ignoreReadonly = true;
    // options.debug = true
    return options;
  }

  defaultFormat() {
    if (this.input.hasClass('datetime-picker')) {
      return 'Y-MM-DD hh:mm A';
    } else if (this.input.hasClass('time-picker')) {
      return 'hh:mm A';
    } else {
      return 'Y-MM-DD';
    }
  }

  replaceGlyphiconIcon(direction){
    var icon = $('.glyphicon-chevron-' + direction);
    if(icon){
      icon.addClass('fa fa-caret-' + direction);
      icon.removeClass('glyphicon glyphicon-chevron-' + direction);
    }
  }
};

$(document).on('turbolinks:load', function() {
  const inputs = $('input.datetime-picker, input.date-picker, input.time-picker');
  if (!(inputs.length > 0)) { return; }
  return inputs.each((index, input) => new DateTimePicker($(input)));
});
