import DateTimePicker from './datetimepicker';
import Handlebars from 'handlebars'

export default class AdvancedSearch {
  constructor(el) {
    this.el = el;
    this.form = this.el.closest('form');

    this.el.on('click', '.add-condition', event => {
      this.addCondition($(event.target));
      event.preventDefault();
    });

    this.el.on('click', '.add-grouping', event => {
      this.addGrouping($(event.target));
      event.preventDefault();
    });

    this.el.on('click', '.remove-condition', event => {
      $(event.currentTarget).closest('.condition').remove();
      event.preventDefault();
    });

    this.el.on('change', '.attribute select', event => {
      const $select = $(event.currentTarget);
      // Retrieve the old value
      const oldValue = $select.data('oldValue');
      // Get the new value
      const newValue = $select.val();

      // Update oldValue for future changes
      $select.data('oldValue', newValue);

      if (oldValue !== undefined && oldValue !== newValue) {
        this.handleAttributeChange(event);
      }
    });

    this.el.on('change', '.predicate select', event => {
      this.handlePredicateChange(event);
    });

    this.conditionTemplate = $(this.el.find('.condition:first')[0].outerHTML);
    this.conditionTemplate.find('p').remove();

    this.groupingTemplate = this.getGroupingTemplate();
    this.groupingTemplate.find('.condition').remove();

    this.conditionCounter = $('.condition').length;
    this.groupingCounter = $('grouping').length + 1;

    this.initHandlebarTemplates();

    this.el.find('.attribute select').each((index, select) => {
      $(select).data('oldValue', $(select).val());
      if (this.attrsThatRequireRansackerArgs().indexOf($(select).val()) !== -1) {
        const ransackerArgs = $(select).siblings('input:hidden').val();
        if (ransackerArgs) {
          $(select).find(`option[data-ransacker-args="${ransackerArgs.replace(/"/g, '\\"')}"]`).prop('selected', 'selected');
        }
      }

      this.handleAttributeChange($(select));
    });

    this.saveSearchModal = $('#save_search_modal');
    this.savedSearchNameInput = this.saveSearchModal.find('#saved_search_name');

    this.saveSearchModal.find('form').on('submit', event => {
      return this.saveSearch();
    });

    this.form.find('a.save-search').on('click', event => {
      event.preventDefault();

      if (!this.validateConditions()) {
        return false;
      }
      this.savedSearchNameInput.val(this.defaultSaveSearchName());
      this.saveSearchModal.modal('show');
    });

    this.form.on('submit', event => {
      if (!this.validateConditions()) {
        return false;
      }
    });
  }

  validateConditions() {
    let conditionsAreValid = true;
    this.el.find('.condition').each((index, condition) => {
      if ($(condition).find('.grouping').length)
        return true;

      const attrSelect = $(condition).find('.attribute select');
      const attrRansackerArgs = $(condition).find('.attribute input[id$="ransacker_args"]');
      const value = $(condition).find('.value :input');

      let conditionIsValid = true;
      if (this.attrsThatRequireRansackerArgs().indexOf(attrSelect.val()) !== -1 && !attrRansackerArgs.val()) {
        conditionIsValid = false;
      } else if (!value.val()) {
        conditionIsValid = false;
      }

      let dateConditionIsValid = true;
      if (value.hasClass('date-picker')) {
        dateConditionIsValid = moment(value.val(), 'YYYY-MM-DD', true).isValid();
      }

      let numberConditionIsValid = true
      let selectedOption = attrSelect.find('option:selected');
      if (['number', 'integer', 'decimal'].indexOf(selectedOption.data('type')) !== -1 && isNaN(value.val()))
        numberConditionIsValid = false

      $(condition).find('.alert-warning').remove();
      if (!conditionIsValid) {
        $(condition).find('.media-body').append(this.errorMessage("Oops! This condition is invalid. Please ensure you've filled out each section."));
      } else if (!dateConditionIsValid) {
        $(condition).find('.media-body').append(this.errorMessage("That date value won't work. Please use the date picker to select a date."));
      } else if (!numberConditionIsValid) {
        $(condition).find('.media-body').append(this.errorMessage("You need to enter a number for this condition."));
      }

      if (!conditionIsValid || !dateConditionIsValid || !numberConditionIsValid) { conditionsAreValid = false; }
      return true;
    });

    return conditionsAreValid;
  }

  errorMessage(text) {
    return `<div class="alert alert-warning alert-dismissiblemt-2 mb-0"> \
<p class="mb-0">` + text + `</p> \
</div>`;
  }

  attrsThatRequireRansackerArgs() {
    return [
      'user_extended_user_profile_form_submission_values_form_field_value',
      'extended_user_profile_form_submission_values_form_field_value',
      'extended_transaction_profile_form_submission_values_form_field_value',
      'user_extended_user_profile_form_submission_values_number_field_value',
      'extended_user_profile_form_submission_values_number_field_value',
      'extended_transaction_profile_form_submission_values_number_field_value',
      'total_donations_over_time',
      'user_total_donations_over_time',
      'number_of_donations_over_time',
      'user_number_of_donations_over_time',
      'opened_email',
      'user_opened_email',
      'number_of_soft_credits_over_time',
      'user_number_of_soft_credits_over_time',
      "total_household_donations_sum_over_time"
    ];
  }

  // This method uses option names instead of values b/c several of the
  // "Total donation amount" and "Number of donations" share the same values
  // and differ only by ransacker args.
  attrsThatRequireCustomDateRange() {
    return ['Total donation amount (custom date range)', 'Number of donations (custom date range)', 'Opened email blast (custom date range)', 'Number of soft credits (custom date range)', 'Total household donation amount (custom date range)', 'Total sponsorship amount (custom date range)'];
  }

  addCondition(link) {
    const conditions = link.closest('.conditions');
    const lastCondition = conditions.children('.condition').last();

    const prefix = conditions.data('prefix');
    const newCondition = this.getNewCondition(prefix);

    // Destroy any existing Select2 instances and remove their DOM elements
    newCondition.find('select.select2-hidden-accessible').each(function() {
      if ($(this).data('select2')) {
        $(this).select2('destroy');
      }
      // Remove the Select2 container element that was added by Select2
      $(this).next('.select2-container').remove();
    });

    if (lastCondition.length) {
      lastCondition.after(newCondition);
    } else {
      conditions.prepend(newCondition);
    }

    // Initialize Select2 after inserting the new condition into the DOM
    newCondition.find('.select2').select2();

    const selectElement = newCondition.find('.attribute select');
    selectElement.data('oldValue', selectElement.val());
    this.handleAttributeChange(newCondition.find('.attribute'));
  }


  addGrouping(link) {
    const newGrouping = this.groupingTemplate.clone();
    const conditions = $(link).closest('.conditions');
    const lastCondition = conditions.children('.condition').last();

    const prefix = `${conditions.data('prefix')}[g][${this.groupingCounter}]`;
    newGrouping.find('.conditions').attr('data-prefix', prefix);
    newGrouping.find('.combinator select').attr('name', `${prefix}[m]`);

    const newCondition = this.getNewCondition(prefix);
    newGrouping.find('.conditions').prepend(newCondition);

    if (lastCondition.length) {
      newGrouping.insertAfter(lastCondition);
    } else {
      conditions.prepend(newGrouping);
    }

    newGrouping.find('.select2').select2()

    const selectElement = newGrouping.find('.attribute select');
    selectElement.data('oldValue', selectElement.val());
    this.groupingCounter += 1;
  }

  getNewCondition(prefix) {
    const newCondition = this.conditionTemplate.clone();
    newCondition.attr("id", this.sanitizeToId(`condition_${prefix}[c][${this.conditionCounter}]`));
    newCondition.find(':input').each((index, input) => {
      let name = $(input).attr('name');
      name = name.replace('q[g][0]', prefix);
      name = name.replace('[c][0]', `[c][${this.conditionCounter}]`);
      const id = this.sanitizeToId(name);
      $(input).attr('name', name);
      $(input).attr('id', id);
    });

    newCondition.find('.attribute :input')[0].selectedIndex = 0;
    newCondition.find('.attribute input:hidden').val('');
    newCondition.find('.predicate select').val('cont');
    newCondition.find('.value input').val('');
    newCondition.find('.custom-date-range').remove();

    this.conditionCounter += 1;
    return newCondition;
  }

  getGroupingTemplate() {
    return $(`<div class=\"form-group condition\"> \
<div class=\"media\"> \
<div class=\"media-left mr-2\"> \
<a href=\"#\" class=\"btn btn-link remove-condition\"><i class=\"fa fa-times\"></i></a> \
</div> \
<div class=\"media-body\"> \
<div class=\"grouping card p-2\">${$('.conditions:first')[0].outerHTML}</div> \
</div> \
</div> \
</div>`);
  }

  getSearchType() {
    if (this.isDonationSearch()) {
      return 'DonationsSavedSearch';
    } else {
      return 'UsersSavedSearch';
    }
  }

  saveSearch() {
    let data = this.form.serialize();
    data += `&saved_search[name]=${escape(this.savedSearchNameInput.val())}`;
    data += `&saved_search[search_type]=${this.getSearchType()}`;
    $.post('/saved_searches.js', data);
    return false;
  }

  isUserSearch() {
    return $('body').hasClass('users') || ($('body').hasClass('saved_searches') && ($('#search_type').val() === 'users'));
  }

  isDonationSearch() {
    return !this.isUserSearch();
  }

  defaultSaveSearchName() {
    let name = '';
    this.el.find('.condition').each((index, condition) => {
      if (name !== '') {
        name += ', ';
      }

      name += $(condition).find('.attribute option:selected').text() + ' ';
      name += $(condition).find('.predicate option:selected').text() + ' ';
      // if the value field is neither a select or a text field
      // then it is a hidden field and we don't want to add
      // its value to the name
      if ($(condition).find('.value select').length) {
        name += $(condition).find('.value option:selected').text();
      } else if ($(condition).find('.value input[type="text"]').length) {
        name += $(condition).find('.value input[type="text"]').val();
      }
    });
    return name.trim();
  }

  handleAttributeChange(select) {
    // called both when the attribute value changes
    // and when the edit form is loaded.
    // When called on attribute change, the select
    // value is an event. When called on form load
    // The select value is the attribute select field
    let isChangeEvent = false;
    if (select.target) {
      select = $(select.target);
      isChangeEvent = true;
    }

    const selectedOption = select.find('option:selected');

    this.setPredicateOptions(selectedOption, select);
    this.determineInputValueFieldFormat(select, isChangeEvent);

    if (isChangeEvent) {
      const ransackerArgsInput = select.siblings('input:hidden');
      ransackerArgsInput.val('');
      if (selectedOption.data('ransacker-args')) {
        let ransackerArgs = selectedOption.data('ransacker-args');
        // For total_donations_over_time, we may need to pass an array [from, to].  The best
        // way I could think to do this was to serialize using JSON.stringify here on
        // the client, and then convert back to an array using JSON.parse on the server.
        if (ransackerArgs instanceof Array) { ransackerArgs = JSON.stringify(ransackerArgs); }
        ransackerArgsInput.val(ransackerArgs);
      }

      const attribute = select.closest('.attribute');
      attribute.find('p').remove();

      const selectedOptionText = select.find('option:selected').text();
      if (this.attrsThatRequireCustomDateRange().includes(selectedOptionText)) {
        attribute.append('<p class="custom-date-range form-text text-muted mb-0"><a href="#" data-toggle="modal" data-target="#custom_date_range_modal">Select date range</a></p>');
        attribute.find('.custom-date-range a').trigger('click');
      } else if (selectedOptionText == 'Donated consecutive years') {
        attribute.append('<p class="form-text text-muted mb-0">Made at least one donation in each of the previous X calendar years (does not include this year).  Limit 10.</p>');
      } else if (selectedOptionText.startsWith('Distance (in miles) from zip code')) {
        attribute.append('<p class="miles-from-zip-code form-text text-muted mb-0"><a href="#" data-toggle="modal" data-target="#miles_from_zip_code_modal">Enter miles and a zip code</a></p>');
        attribute.find('.miles-from-zip-code a').trigger('click');
        attribute.next('.predicate').find('select').val('lteq');
      }
    }
  }

  handlePredicateChange(select) {
    // called on predicate change
    const predicateSelect = $(select.target);
    const attributeSelect = predicateSelect.closest('.condition').find('.attribute select');

    this.determineInputValueFieldFormat(attributeSelect, true);
  }

  sanitizeToId(name) {
    return name.replace(/\]\[/g, '_').replace(/\[/g, '_').replace(/\]/g,'');
  }

  replaceInputValueField(input, field, value) {
    field.val(value);
    input.replaceWith(field);
    return field;
  }

  determineInputValueFieldFormat(attributeSelect, isChangeEvent) {
    let newInput;
    const predicateSelect = attributeSelect.closest('.condition').find('.predicate :input');
    let attribute = attributeSelect.val();
    const predicate = predicateSelect.val();
    const input = attributeSelect.closest('.condition').find('.value :input');
    const name = input.attr('name');
    let value = input.val();
    if (isChangeEvent) { value = ''; }
    const selectAttributes = [
      'type', 'user_type', 'gender', 'user_gender', 'home_address_state', 'user_home_address_state',
      'work_address_state', 'user_work_address_state', 'home_address_country', 'user_home_address_country',
      'work_address_country', 'user_work_address_country', 'groups_id', 'user_groups_id',
      'birthday_month', 'user_birthday_month', 'donations_fund_id', 'user_donations_fund_id',
      'donated_to_designation', 'donations_campaign_id', 'user_donations_campaign_id',
      'ticket_purchases_campaign_id', 'user_ticket_purchases_campaign_id', 'donated_to_campaign',
      'purchased_tickets_to_event', 'payment_method', 'tribute_notification_method',
      'extended_user_profile_form_submission_values_form_field_value',
      'user_extended_user_profile_form_submission_values_form_field_value',
      'extended_transaction_profile_form_submission_values_form_field_value',
      'recurring_donation_frequency', 'donor_status', 'user_donor_status',
      'ticket_type_id', 'pledges_campaign_id', 'user_pledges_campaign_id', 'source',
      'sponsorship_purchases_campaign_id', 'purchased_sponsorship_to_event', 'sponsorship_type_id', 'payment_gateway_provider'
    ];
    const booleanPredicateValues = ['blank', 'present', 'true', 'false', 'null', 'not_null'];

    if (booleanPredicateValues.indexOf(predicate) !== -1) {
      // always use a hidden field if the prediciate is boolean and store a value of true (the value isn't when running the query)
      this.replaceInputValueField(input, $(this.valueHiddenFieldTemplate({ name, id: this.sanitizeToId(name) })), true);
    } else if (selectAttributes.indexOf(attribute) !== -1) {
      // otherwise, use a value field that is appropriate for
      // the attribute that was selected

      switch (attribute) {
        case 'gender': case 'user_gender':
          this.replaceInputValueField(input, $(this.genderSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'user_type':
          this.replaceInputValueField(input, $(this.userTypeSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'type':
          // this needs to be conditional b/c we have type for users and transactions
          if (this.isUserSearch()) {
            this.replaceInputValueField(input, $(this.userTypeSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          } else {
            this.replaceInputValueField(input, $(this.transactionTypeSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          }
          break;
        case 'home_address_state': case 'work_address_state': case 'user_home_address_state': case 'user_work_address_state':
          this.replaceInputValueField(input, $(this.usStateSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'home_address_country': case 'work_address_country': case 'user_home_address_country': case 'user_work_address_country':
          this.replaceInputValueField(input, $(this.countrySelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'groups_id': case 'user_groups_id':
          this.replaceInputValueField(input, $(this.groupSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'birthday_month': case 'user_birthday_month':
          this.replaceInputValueField(input, $(this.monthSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'donations_fund_id': case 'user_donations_fund_id': case 'donated_to_designation':
          this.replaceInputValueField(input, $(this.fundSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'donations_campaign_id': case 'user_donations_campaign_id': case 'donated_to_campaign': case 'pledges_campaign_id': case 'user_pledges_campaign_id':
          this.replaceInputValueField(input, $(this.campaignSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'ticket_purchases_campaign_id': case 'user_ticket_purchases_campaign_id': case 'purchased_tickets_to_event': case 'sponsorship_purchases_campaign_id': case 'purchased_sponsorship_to_event':
          this.replaceInputValueField(input, $(this.eventSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'payment_method':
          this.replaceInputValueField(input, $(this.paymentMethodSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'payment_gateway_provider':
          this.replaceInputValueField(input, $(this.paymentGatewayProviderSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'tribute_notification_method':
          this.replaceInputValueField(input, $(this.tributeNotificationMethodSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'recurring_donation_frequency':
          this.replaceInputValueField(input, $(this.recurringDonationFrequencySelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'donor_status': case 'user_donor_status':
          this.replaceInputValueField(input, $(this.donorStatusSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'extended_user_profile_form_submission_values_form_field_value': case 'user_extended_user_profile_form_submission_values_form_field_value': case 'extended_transaction_profile_form_submission_values_form_field_value':
          var source = $(`#form-field-${attributeSelect.find('option:selected').data('ransacker-args')}-select`).html();
          if (source) {
            const template = Handlebars.compile(source);
            this.replaceInputValueField(input, $(template({ name, id: this.sanitizeToId(name) })), value);
          } else {
            newInput = this.replaceInputValueField(input, $(this.valueTextFieldTemplate({ name, id: this.sanitizeToId(name) })), value);
          }
          break;
        case 'ticket_type_id':
          this.replaceInputValueField(input, $(this.ticketTypeSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'sponsorship_type_id':
          this.replaceInputValueField(input, $(this.sponsorshipTypeSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
        case 'source':
          this.replaceInputValueField(input, $(this.sourceSelectTemplate({ name, id: this.sanitizeToId(name) })), value);
          break;
      }
    } else {
      newInput = this.replaceInputValueField(input, $(this.valueTextFieldTemplate({ name, id: this.sanitizeToId(name) })), value);
    }

    if (/^date/.test(attributeSelect.find('option:selected').data('type'))) {
      newInput.addClass('date-picker');
      new DateTimePicker(newInput);

      newInput.on('dp.change', function(e) {
        // The timezone offset is dynamically set here to account for daylight savings.
        // For instance, if it's November 13th, the current offset is -05:00.  But, if
        // the admin wants to search for last donation date of October 13th, the offset
        // then was -04:00.  This is important b/c we truncate datetimes to dates in order
        // to search using the equals predicate.
        if (!e.date) { return; }

        attribute = $(this).closest('.condition').find('.attribute');

        // We don't want to update the ransacker args for custom fields that are
        // dates b/c we use them (the ransacker args) to pass in the field id.
        const attributeSelectVal = attribute.find('select').val();
        if (attributeSelectVal.match(/profile_form_submission_values_form_field_value/)) { return; }

        const ransackerArgsInput = attribute.find('input:hidden');
        if (!ransackerArgsInput.length) { return; }
        const tzOffset = e.date.format('Z');
        ransackerArgsInput.val(tzOffset);
      });
    }
  }

  setPredicateOptions(selectedOption, attributeSelect) {
    const predicateSelect = attributeSelect.closest('.condition').find('.predicate :input');
    const dataType = selectedOption.data('type');
    const name = predicateSelect.attr('name');
    const original_value = predicateSelect.val();

    switch (dataType) {
      case 'boolean': predicateSelect.html($(this.booleanPredicatesTemplate())); break;
      case 'decimal': case 'integer': case 'date': case 'datetime': case 'number': predicateSelect.html($(this.numberPredicatesTemplate())); break;
      case 'picklist': predicateSelect.html($(this.picklistPredicatesTemplate())); break;
      case 'optional_picklist': case 'select': case 'radio_buttons': predicateSelect.html($(this.optionalPicklistPredicatesTemplate())); break;
      case 'optional_picklist2': predicateSelect.html($(this.optionalPicklist2PredicatesTemplate())); break;
      case 'custom_in': predicateSelect.html($(this.customInPredicatesTemplate())); break;
      default: predicateSelect.html($(this.stringPredicatesTemplate()));
    }

    if (predicateSelect.find(`option[value=${original_value}]`).length > 0) {
      predicateSelect.val(original_value);
    }
  }

  initHandlebarTemplates() {
    let source = $('#gender_select').html();
    this.genderSelectTemplate = Handlebars.compile(source);

    source = $('#us_state_select').html();
    this.usStateSelectTemplate = Handlebars.compile(source);

    source = $('#country_select').html();
    this.countrySelectTemplate = Handlebars.compile(source);

    source = $('#group_select').html();
    this.groupSelectTemplate = Handlebars.compile(source);

    source = $('#fund_select').html();
    this.fundSelectTemplate = Handlebars.compile(source);

    source = $('#campaign_select').html();
    this.campaignSelectTemplate = Handlebars.compile(source);

    source = $('#event_select').html();
    this.eventSelectTemplate = Handlebars.compile(source);

    source = $('#month_select').html();
    this.monthSelectTemplate = Handlebars.compile(source);

    source = $('#user_type_select').html();
    this.userTypeSelectTemplate = Handlebars.compile(source);

    source = $('#donor_status_select').html();
    this.donorStatusSelectTemplate = Handlebars.compile(source);

    source = $('#value_text_field').html();
    this.valueTextFieldTemplate = Handlebars.compile(source);

    source = $('#value_hidden_field').html();
    this.valueHiddenFieldTemplate = Handlebars.compile(source);

    source = $('#string_predicates').html();
    this.stringPredicatesTemplate = Handlebars.compile(source);

    source = $('#boolean_predicates').html();
    this.booleanPredicatesTemplate = Handlebars.compile(source);

    source = $('#number_predicates').html();
    this.numberPredicatesTemplate = Handlebars.compile(source);

    source = $('#picklist_predicates').html();
    this.picklistPredicatesTemplate = Handlebars.compile(source);

    source = $('#optional_picklist_predicates').html();
    this.optionalPicklistPredicatesTemplate = Handlebars.compile(source);

    source = $('#optional_picklist2_predicates').html();
    this.optionalPicklist2PredicatesTemplate = Handlebars.compile(source);

    source = $('#custom_in_predicates').html();
    this.customInPredicatesTemplate = Handlebars.compile(source);

    if (this.isDonationSearch()) {
      source = $('#transaction_type_select').html();
      this.transactionTypeSelectTemplate = Handlebars.compile(source);

      source = $('#ticket_type_select').html();
      this.ticketTypeSelectTemplate = Handlebars.compile(source);

      source = $('#sponsorship_type_select').html();
      this.sponsorshipTypeSelectTemplate = Handlebars.compile(source);

      source = $('#source_select').html();
      this.sourceSelectTemplate = Handlebars.compile(source);

      source = $('#tribute_notification_method_select').html();
      this.tributeNotificationMethodSelectTemplate = Handlebars.compile(source);

      source = $('#recurring_donation_frequency_select').html();
      this.recurringDonationFrequencySelectTemplate = Handlebars.compile(source);

      source = $('#payment_method_select').html();
      this.paymentMethodSelectTemplate = Handlebars.compile(source);

      source = $('#payment_gateway_provider_select').html();
      this.paymentGatewayProviderSelectTemplate = Handlebars.compile(source);
    }
  }
};

$(document).on('turbolinks:load', function() {
  const el = $('#advanced_search_container');
  if (el.length > 0)
    new AdvancedSearch(el);

  $('a#create_filter').click(function() {
    if ($('#empty_advanced_search_form').length) {
      $.post('/advanced_searches/load.js', {
        search_type: ($('body').hasClass('users') ? 'users' : 'donations'),
        q: gon.params_q
      })
    }
  })
});
