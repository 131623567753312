export default class Loader {
  constructor(el) {
    this.el = el;
    this.loader = this.el.find('.loader');
  }

  loaded() {
    this.el.removeClass('loading');
    this.el.addClass('loaded');
    this.el.children(':not(.loader)').addClass('loader-section');
    this.remove();
  }

  remove() {
    // Acts as a fallback for legacy browsers that don't support css animation/transition
    // as well as enabling elements to be clicked under the loader 250 ms is for
    // the css animation/transition
    setTimeout( () => {
      this.loader.addClass('d-none');
    }, 250);
  }

  reset(clearContent) {
    if (clearContent == null) { clearContent = true; }
    if (clearContent) { this.el.children(':not(.loader)').remove(); }
    this.el.removeClass('loaded');
    this.el.removeClass('loading');
    this.loader.removeClass('d-none');
  }
};
