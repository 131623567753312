// Installs error handler for Email Gallery search form
class EmailGallerySearchForm {
  constructor(formElement, alertElement) {
    this.formElement = formElement;
    this.alertElement = alertElement;

    $(formElement).on("ajax:error", (event) => {
      var detail = event.detail;
      var statusCode = detail[1],  xhr = detail[2];
      console.warn('Received XHR error:', statusCode);

      // TODO: change this to something more onbrand
      alertElement.html('We were unable to complete your search. Please try again.');
      alertElement.attr('hidden', false)
    });
  }
};

$(document).on('turbolinks:load', function() {
  const searchForm = $('form#email_gallery_search_box');
  if (!(searchForm.length > 0)) { return; }

  const searchAlert = $('#email_gallery_search_alert');
  new EmailGallerySearchForm(searchForm, searchAlert);
});
