class VideoAcknowledgementForm {
  constructor(form) {
    // These are shared by mobile and browser recording
    this.form = form;
    this.deliveryTypeRadioButtons = this.form.find('input[name="video_acknowledgement[delivery_type]"]');
    this.messageField = this.form.find('#video_acknowledgement_message');
    this.subjectField = this.form.find('#video_acknowledgement_subject')
    this.subjectFormGroup = this.subjectField.closest('.form-group')

    this.deliveryTypeRadioButtons.click((e) => {
      this.updateMessage(e.target.value);
      this.toggleSubject(e.target.value);
    });
  }

  updateMessage(deliveryType) {
    // const deliveryType = this.deliveryTypeRadioButtons.filter(':checked').val();
    const message = this.messageField.val()

    // If the message has been updated by the admin, then we don't want to overwrite it.
    if (!this.messageMatchesDefault(message)) { return; }

    if (deliveryType == 'email')
      this.messageField.val(gon.email_message);
    else // text_message
      this.messageField.val(gon.text_message_message);
  }

  toggleSubject(deliveryType) {
    if (deliveryType == 'text_message')
      this.subjectFormGroup.addClass('d-none');
    else // 'email'
      this.subjectFormGroup.removeClass('d-none');
  }

  messageMatchesDefault(message) {
    return (
      (this.normalizeNewLines(message) == this.normalizeNewLines(gon.email_message)) ||
      (this.normalizeNewLines(message) == this.normalizeNewLines(gon.text_message_message))
    );
  }

  normalizeNewLines(str) {
    return str.replace(/(\r\n|\n|\r)/gm, "\n")
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#new_video_acknowledgement');
  if (!(form.length > 0)) { return; }
  new VideoAcknowledgementForm(form);
});

$(document).ready(function() {
  $('.toggle_button').click(function(event) {
    event.preventDefault();
    $('#video_recording').toggleClass('d-none');
    $('#video_uploading').toggleClass('d-none');
  });
});