export default class UserProfileGroups {
  constructor(el) {
    this.el = el;
    this.form = this.el.find('form');
    this.userId = this.form.data('user-id');

    this.form.submit(() => false);

    this.form.find(':checkbox').click(event => {
      this.addOrRemoveGroup($(event.target));
    });
  }

  addOrRemoveGroup(checkbox) {
    const groupId = checkbox.val();
    const options = {
      type: 'POST',
      data: { user_id: [this.userId] },
      dataType: 'json'
    };

    if (checkbox.is(':checked')) {
      options.url = `/groups/${groupId}/add_users`;
    } else {
      options.url = `/groups/${groupId}/remove_users`;
      options.data['_method'] = 'delete';
    }

    $.ajax(options);
  }
};
