class OnboardingPreviewIframe {
  constructor(iframe) {
    // Sets preview thumbnail's height
    this.iframe = iframe;
    this.iframe.on('load', e => {
      this.setPreviewThumbnailHeight();
    });
  }

  setPreviewThumbnailHeight() {
    const height = this.iframe.attr('height') || this.iframe[0].contentDocument.body.scrollHeight;
    const zoom = .4;
    this.iframe[0].height = height;
    this.iframe.closest('.preview-thumbnail-container').height(height * this.zoom());
  }

  zoom() {
    if (this.iframe.attr('id') == 'email_preview')
      return 0.4;
    else // mailing_preview
      return 0.34;
  }
};

$(document).on('turbolinks:load', function() {
  const iframe = $('iframe#email_preview, iframe#mailing_preview');
  if (!(iframe.length > 0)) { return; }
  new OnboardingPreviewIframe(iframe);
});
