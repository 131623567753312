class PledgeForm {
  constructor(form) {
    this.form = form;
    this.periodSelect = this.form.find('select#pledge_period');
    this.startDateBlock = this.form.find('#pledge_start_date_wrapper');

    this.periodSelect.on('change', event => {
      this.toggleStartDateBlock();
    });

    this.toggleStartDateBlock();
  }

  toggleStartDateBlock() {
    if ((this.periodSelect.val() === 'one_time') || (this.periodSelect.val() === '')) {
      this.startDateBlock.addClass('d-none');
    } else {
      this.startDateBlock.removeClass('d-none');
    }
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#pledge_form');
  if (!(form.length > 0)) { return; }
  new PledgeForm(form);
});
