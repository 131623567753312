export default class Alert {
  constructor(content, type = 'success', autoRemove = true) {
    this.content = content;
    this.type = type;
    this.id = 'alert' + String(new Date().getTime());
    this.autoRemove = autoRemove;
  }

  display() {
    $('.alert[data-position-absolute="true"]:visible').remove();

    $('body').prepend(`
      <div id='${this.id}' class='alert alert-dismissible alert-${this.type}' data-position-absolute="true" role='alert'>
        <button aria-label='Close' class='close' data-dismiss='alert' type='button'>
          <span aria-hidden='true'><i class="fa fa-times"></i></span>
        </button>
        <div class='media'>
          <div class='media-left mr-2'>
            <i class="fa fa-${this.getIcon()} font-size-xl"></i>
          </div>
          <div class='media-body'>
            <p>${this.content}</p>
          </div>
        </div>
      </div>
    `);

    if (this.autoRemove)
      setTimeout(() => this.remove(), 5000);
  }

  remove() {
    let el = $(`#${this.id}`)
    el.removeClass('in').addClass('out');
    setTimeout(() => el.remove(), 500);
  }

  // This method mirrors the flash_icon method in ApplicationHelper.rb
  getIcon() {
    let icon;

    switch (this.type) {
      case 'success':
        icon = 'check-circle';
        break;
      case 'danger':
      case 'error':
      case 'warning':
        icon = 'exclamation-circle';
        break;
      case 'info':
        icon = 'info-circle';
        break;
      default:
        icon = 'chevron-circle-right';
    };

    return icon;
  }
}

$(document).on('turbolinks:load', function() {
  $('.alert[data-auto-dismiss="true"]').each(function() {
    var alert = $(this);
    setTimeout(function() {
      alert.removeClass('in').addClass('out');
      setTimeout(() => alert.remove(), 500);
    }, 5000);
  });
});