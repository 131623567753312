class EmailCreatorLayoutTabs {
  constructor(tabs) {
    this.tabs = tabs;
    this.layoutTypeHiddenField = $('#onboarding_email_creator_layout_layout_type');

    this.tabs.on('click', 'a[data-toggle="tab"]', e => {
      const link = $(e.currentTarget);
      if (link.hasClass('disabled')) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      this.handleTabLinkClick(link);
    });

    // Ensures the currect tab/section is selected on page load based on the email's layout_type.
    const layoutType = this.layoutTypeHiddenField.val();
    this.tabs.find(`li[data-layout-type='${layoutType}'] a[data-toggle="tab"]`).trigger('click');
  }

  handleTabLinkClick(link) {
    const layoutType = link.closest('li').data('layout-type');
    this.layoutTypeHiddenField.val(layoutType);
  }
};

$(document).on('turbolinks:load', function() {
  const tabs = $('body.onboarding-email_creator.layout #layout_tabs');
  if (!(tabs.length > 0)) { return; }
  new EmailCreatorLayoutTabs(tabs);
});
