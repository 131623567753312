class UserForm {
  constructor(form) {
    this.form = form;
    this.nameInput = this.form.find('#user_name:first');
    this.dupContactsHelper = this.form.find('#dup-contacts-helper');
    this.typeCheckables = this.form.find(':radio[name="user[type]"], :checkbox[name="user[type]"]');
    this.individualFields = this.form.find('#individual_fields');
    this.organizationFields = this.form.find('#organization_fields');

    this.pointOfContactRelationshipIdInput = this.organizationFields.find('#user_point_of_contact_relationship_attributes_id');
    this.pointOfContactRelationshipTypeField = this.organizationFields.find('#point_of_contact_relationship_type_field');
    this.pointOfContactSelect = this.organizationFields.find('#user_point_of_contact_relationship_attributes_dest_user_id');
    this.pointOfContactSelect.change(event => {
      this.togglePointOfContactRelationshipTypeField();
    });
    this.togglePointOfContactRelationshipTypeField();

    this.genderSelect = this.form.find('#user_gender');
    this.customGenderInput = this.form.find('#user_custom_gender_value');

    this.typeCheckables.click(event => {
      const checkable = $(event.currentTarget);
      let proceed = true;
      if (checkable.is(':checkbox:checked')) {
        proceed = confirm(`Are you sure you want to switch this contact to an ${checkable.attr('value').toLowerCase()}?`);
      }
      if (proceed) {
        this.switchType();
      } else {
        event.preventDefault();
      }
    });

    if (this.isNewContact()) {
      this.nameInput.on('blur', () => {
        this.searchExistingUsers();
      });
    }

    this.form.on('submit', () => {
      this.removeTypeFields();
    });

    this.genderSelect.change(event => {
      if (this.genderSelect.val() == 'c')
        this.customGenderInput.removeClass('d-none')
      else
        this.customGenderInput.addClass('d-none')
    })
  }

  togglePointOfContactRelationshipTypeField() {
    // _destroy is a Rails, accepts_nested_attributes_for thing that marks the
    // record for destruction.
    this.organizationFields.find('#user_point_of_contact_relationship_attributes_destroy').remove();

    if (this.pointOfContactSelect.val() !== '') {
      this.pointOfContactRelationshipTypeField.removeClass('d-none');
    } else {
      this.pointOfContactRelationshipTypeField.addClass('d-none');
      if (this.pointOfContactRelationshipIdInput.length) {
        this.organizationFields.append('<input type="hidden" value="1" name="user[point_of_contact_relationship_attributes][_destroy]" id="user_point_of_contact_relationship_attributes_destroy">');
      }
    }
  }

  // There are shared fields (ex: name) in the individual and organization sections.
  // We need to remove the unused duplicates so the data is submitted properly.
  removeTypeFields() {
    const userType = this.userType();
    if (userType === 'Individual') {
      this.organizationFields.remove();
    } else if (userType === 'Organization') {
      this.individualFields.remove();
    }
  }

  userType() {
    if (this.typeCheckables.filter(':checked').length) {
      return this.typeCheckables.filter(':checked').val();
    } else {
      return this.form.find(':hidden[name="user[type]"]').val();
    }
  }

  switchType() {
    const userType = this.userType();
    if (userType === 'Individual') {
      this.individualFields.removeClass('d-none');
      this.organizationFields.addClass('d-none');
    } else if (userType === 'Organization') {
      this.individualFields.addClass('d-none');
      this.organizationFields.removeClass('d-none');
    }
  }

  searchExistingUsers() {
    if (this.nameInput.val().length === 0) {
      this.dupContactsHelper.addClass('d-none');
      return;
    }
    $.getJSON(`/users.json?q[name_cont]=${encodeURIComponent(this.fullName())}`, users => {
      if (users.length > 0) {
        const user = users[0];
        const msg = `<div class='alert alert-warning mt-2 mb-0'><p class='mb-0'>${this.duplicateContactMessage(user)}</p></div>`;
        this.dupContactsHelper.html(msg);
        this.dupContactsHelper.removeClass('d-none');
      } else {
        this.dupContactsHelper.addClass('d-none');
      }
    });
  }

  fullName() {
    return this.nameInput.val();
  }

  duplicateContactMessage(user) {
    let msg;
    if (this.includeLinksInDupContactMessage()) {
      msg = 'Contact <a href=\"/users/USER_ID\">USER_NAME</a> already exists. Would you like to <a href=\"/users/USER_ID/edit\">edit him/her instead</a>?';
    } else {
      msg = 'Contact USER_NAME</a> already exists.';
    }
    return msg.replace(/USER_ID/g, user.id).replace(/USER_NAME/g, this.fullName());
  }

  isNewContact() {
    return this.form.attr('action') === '/users';
  }

  includeLinksInDupContactMessage() {
    return this.dupContactsHelper.data('include-links');
  }
};

$(document).on('turbolinks:load', function() {
  const forms = $('form.user-form');
  if (!(forms.length > 0)) { return; }
  forms.each(function() {
    new UserForm($(this));
  });
});
