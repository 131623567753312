class EmailForm {
  constructor(form) {
    this.form = form;
    this.layoutTypeRadioButtons = this.form.find('input[name="email[layout_type]"]');
    this.starterLayouts = this.form.find('#starter-layouts');
    this.savedTemplates = this.form.find('#saved-templates');

    this.layoutTypeRadioButtons.click(() => {
      this.switchLayoutType();
    });
    this.switchLayoutType();
  }

  switchLayoutType() {
    if (this.layoutTypeRadioButtons.eq(0).is(':checked')) {
      this.starterLayouts.removeClass('d-none');
      this.savedTemplates.addClass('d-none');
    } else if (this.layoutTypeRadioButtons.eq(1).is(':checked')) {
      this.starterLayouts.addClass('d-none');
      this.savedTemplates.removeClass('d-none');
    }
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#email-form');
  if (!(form.length > 0)) { return; }
  new EmailForm(form);
});
