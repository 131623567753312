export default class AcknowledgementDropdowns {
  constructor() {
    const recordVideoLinks = $('.acknowledgement-btn-group[data-acknowledged="false"] a[id^="send_video_acknowledgement_within_dropdown_link"]');
    if (recordVideoLinks.length)
      recordVideoLinks.first().closest('[data-describe="donation-listing-row"]').addClass('first-unacknowledged-donation-with-record-video-link')
  }
};

$(document).on('turbolinks:load', function() {
  const dropdowns = $('.acknowledgement-btn-group');
  if (!(dropdowns.length > 0)) { return; }
  new AcknowledgementDropdowns();
});
