$(document).on('turbolinks:load', function() {
  if (!('ontouchstart' in window)) { // Disable tooltips on touch devices
    $('[data-toggle="tooltip"]').tooltip({trigger: 'hover', container: 'body', html: true});

    // Re-fire and turn on tooltips when a modal fires
    // Allows tooltips to show up on modals that load content async.
    $('.modal').on('shown.bs.modal', function() {
      $(this).find('[data-toggle="tooltip"]').tooltip({trigger: 'hover', container: '.modal', html: true});
    });

    $('[data-toggle="tooltip"]').on('click', function() {
      $(this).tooltip('hide');
    });
  }
});
