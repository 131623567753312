class CharacterCounter {
  constructor(el) {
    this.el = el;
    this.characterLimit = this.el.data('character-limit');
    this.formGroup = this.el.closest('.form-group');
    this.counterOutput = this.formGroup.find("[data-toggle='character-count']");
    this.dangerClass = 'text-danger';
    this.staticClass = 'text-muted';

    this.count();

    this.el.on('keyup', e => {
      return this.count();
    });
  }

  count() {
    const characterCount = this.el.val().length;
    const countOutput = this.characterLimit - characterCount;

    this.counterOutput.text(countOutput);
    this.decorate(countOutput);
  }

  decorate(count) {
    if (count < 0) {
      this.counterOutput.removeClass(this.staticClass);
      this.counterOutput.addClass(this.dangerClass);
    } else {
      this.counterOutput.removeClass(this.dangerClass);
      this.counterOutput.addClass(this.staticClass);
    }
  }
};

$(document).on('turbolinks:load', function() {
  const el = $("input[data-toggle='character-counter']");
  if (!el.length) { return; }
  el.each(function() {
    new CharacterCounter($(this));
  });
});
