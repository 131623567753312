class MilesFromZipCodeModal {
  constructor(modal) {
    this.modal = modal;
    this.form = this.modal.find('form');
    this.milesField = this.form.find('#miles');
    this.zipCodeField = this.form.find('#zip_code');

    $('body').on('click', '.conditions p.miles-from-zip-code a', event => {
      this.prefill($(event.target));
    });

    this.form.on('submit', event => {
      return this.handleFormSubmission();
    });
  }

  open() {
    this.modal.modal('open');
  }

  reset() {
    this.form[0].reset();
  }

  prefill(link) {
    const ransackArgsHiddenInput = link.closest('.attribute').find('input:hidden');
    const zipCode = ransackArgsHiddenInput.val();
    const miles = link.closest('.condition').find('.value input:text').val()

    if (zipCode !== '') {
      this.zipCodeField.val(zipCode);
      this.milesField.val(miles);
    } else {
      this.reset();
    }

    this.form.find('#ransacker_ars_id').val(ransackArgsHiddenInput.attr('id'));
  }

  handleFormSubmission() {
    const miles = this.milesField.val();
    const zipCode = this.zipCodeField.val();

    if (miles == '' || zipCode == '')
      return false;

    const ransackerArgsHiddenField = $(`#${this.form.find('#ransacker_ars_id').val()}`);
    const attribute = ransackerArgsHiddenField.closest('.attribute');
    const valueField = ransackerArgsHiddenField.closest('.condition').find('.value input:text')

    ransackerArgsHiddenField.val(zipCode);

    attribute.find('.miles-from-zip-code').remove();
    attribute.append(`<p class=\"miles-from-zip-code form-text text-muted mb-0 font-size-sm\">Zip Code: ${zipCode} <a href=\"#\" data-toggle=\"modal\" data-target=\"#miles_from_zip_code_modal\">Edit</a></p>`);

    valueField.val(miles)

    this.modal.modal('hide');
    return false;
  }
};

$(document).on('turbolinks:load', function() {
  const modal = $('#miles_from_zip_code_modal');
  if (!(modal.length > 0)) { return; }
  new MilesFromZipCodeModal(modal);
});
