$(document).on('turbolinks:load', () => {

  $('.select2').each(function() {
    $(this).select2();
  });

  // Set focus to search text field when we click on select 2 drop down
  $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  });
});
