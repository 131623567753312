class HighlightContent {
  constructor(highlightableContent) {
    this.highlightableContent = highlightableContent;
    this.regexString = /(\{\{.+?\}\})/g; // regEx for: {{ squigs to squigs }}
    this.highlightWrapper = "<mark>$1</mark>";

    this.highlight();
  }

  highlight() {
    this.highlightableContent.find('.content-section[data-type="html"]').each((index, contentSection) => {
      if ($(contentSection).hasClass('redactor-editor')) { return; }
      $(contentSection).html($(contentSection).html().replace(this.regexString, this.highlightWrapper));
    });
  }
};

$(document).on('turbolinks:load', function() {
  const highlightableContent = $("[data-highlightable='true']");
  if (!(highlightableContent.length > 0)) { return; }
  App.hightlightContentInst = new HighlightContent(highlightableContent);
});
