class ContentSections {
  constructor() {
    this.documentHeight = null;
    this.paddingFromEdge = 5;

    this.scrollToTop();
    this.insertEditLinks();
    this.insertRepeatLinks();

    $('body').on('click', '.cs-html-editor-save', event => {
      this.handleHTMLEditorSave($(event.target));
      event.preventDefault();
    });

    $('body').on('click', '.cs-html-editor-cancel', event => {
      this.handleHTMLEditorCancel($(event.target));
      event.preventDefault();
    });

    $('body').on('blur', '.content-section[data-type="text"][contenteditable="true"]', event => {
      this.handleContentEditableBlur($(event.target));
      event.preventDefault();
    });

    $('body').on('click', '.cs-rm-group', event => {
      this.removeRepeatGroup($(event.target));
      event.preventDefault();
    });

    $('body').on('click', '.cs-add-group', event => {
      this.addRepeatGroup($(event.target));
      event.preventDefault();
    });

    $(window).on('beforeunload', () => {
      if (this.hasUnsavedChanges()) {
        return 'Did you forget to click the Save button?  You have unsaved changes that will be lost if you leave this page.';
      }
    });
  }

  insertEditLinks() {
    $('.content-section').each(function() {
      const link = $('<a class="cs-edit btn btn-sm d-print-none" data-remote="true"><i class="fa fa-pencil mr-1"></i>Edit</a>');

      let url = `/content_sections/${$(this).data('id')}/edit?container_id=${$(this).data('container-id')}&container_type=${$(this).data('container-type')}&type=${$(this).data('type')}`;
      link.attr('href', url);
      link.attr('data-for', $(this).data('id'));

      $(this).closest('.content-section-wrapper').prepend(link);
    });
  }

  insertRepeatLinks() {
    // We don't want to show repeat links if there are no content
    // sections - like after it's been delivered.
    if ($('.content-section').length) {
      $('.cs-repeat-group').each(function() {
        const groupId = $(this).data('id');
        const groupName = $(this).data('name');
        const index = $(this).index(`[data-name='${groupName}']`);

        let links = '<div class="cs-btn-group">';

        if (index === 0) {
          links += `<a href="#" class="cs-add-group btn btn-sm" data-for="${groupId}"><i class="fa fa-plus mr-1"></i>Add</a>`;
        }

        links += '<a href="#" class="cs-rm-group btn btn-sm';
        // We don't allow the first group to be deleted, but the rm link still
        // needs to be there b/c we're cloning groups/links when the add link is
        // clicked.
        if (index === 0) {
          links += ' hide';
        }
        links += `" data-for="${groupId}"><i class="fa fa-times mr-1"></i>Remove</a>`;

        links += '</div>';

        $(this).find('th:first').append(links);
      });
    }
  }

  handleHTMLEditorSave(link) {
    const id = link.closest('.cs-html-editor-overlay').data('for');
    const contentSection = $(`.content-section[data-id='${id}']`);

    const form = $(`.cs-form[data-for='${id}']`);
    form.find('#content_section_content').val(contentSection.redactor('code.get'));
    Rails.fire(form[0], 'submit');
  }

  handleHTMLEditorCancel(link) {
    let confirmed = true;
    const htmlEditorOverlay = link.closest('.cs-html-editor-overlay');
    const id = htmlEditorOverlay.data('for');
    const contentSection = $(`.content-section[data-id='${id}']`);
    const htmlEditorUndo = $(`.cs-html-editor-undo[data-for='${id}']`);

    if (this.hasUnsavedChanges(id)) {
      confirmed = confirm('Are you sure you want to cancel? If so, click "OK." All unsaved changes in this section will be lost.');
    }

    if (confirmed) {
      this.cleanUp(id);
      contentSection.html(htmlEditorUndo.html());
    }
  }

  handleContentEditableBlur(el) {
    const form = $(`.cs-form[data-for='${el.data('id')}']`);
    form.find('#content_section_content').val(el.text());
    Rails.fire(form[0], 'submit');
  }

  removeRepeatGroup(link) {
    const groupId = link.attr('data-for');
    const group = $(`.cs-repeat-group[data-id='${groupId}']`);
    let deletePath = '/content_sections?';

    if (group.find('.content-section').length) {
      let cs = group.find('.content-section').first()
      deletePath += `container_id=${cs.data('container-id')}&container_type=${cs.data('container-type')}&`

      group.find('.content-section').each(function() {
        const contentSectionId = $(this).attr('data-id');
        deletePath += `id[]=${contentSectionId}&`;
      });

      // Ajax call to delete the content sections
      $.ajax({
        url: deletePath,
        type: 'DELETE'
      });
    }

    // Remove the container
    group.remove();
  }

  addRepeatGroup(link) {
    const groupId = link.data('for');
    const group = $(`.cs-repeat-group[data-id='${groupId}']`);
    let index = group.data('index');

    let lastGroup = group.siblings('.cs-repeat-group').last();
    if (!lastGroup.length) {
      lastGroup = group;
    }

    const newGroup = group.clone();
    const inners = newGroup.find('.cs-repeat-group-inner');
    let newIndex = lastGroup.data('index') + inners.length;
    const newGroupId = newGroup.data('name') + '_' + newIndex;

    newGroup.attr('data-id', newGroupId);
    newGroup.attr('data-index', newIndex);

    newGroup.find('.cs-edit, .cs-rm-group, .cs-add-group').remove();

    inners.each(function() {
      $(this).find('.content-section').each(function() {
        const contentSectionId = $(this).data('id');
        const newContentSectionId = contentSectionId.replace(`_${index}`, `_${newIndex}`);

        $(this).attr('data-id', newContentSectionId);

        // Add an edit link for each content section in the new group
        const newEditLink = $(`.cs-edit[data-for='${contentSectionId}']`).clone();
        const href = newEditLink.attr('href');
        newEditLink.attr('href', href.replace(contentSectionId, newContentSectionId));
        newEditLink.attr('data-for', newContentSectionId);

        $(this).closest('.content-section-wrapper').append(newEditLink);
      });

      index += 1;
      newIndex += 1;
    });

    // Add a remove link for the new group
    const newRmGroupLink = $(`.cs-rm-group[data-for='${groupId}']`).clone();
    newRmGroupLink.attr('data-for', newGroupId);
    newRmGroupLink.removeClass('hide');
    newGroup.find('.cs-btn-group:first').append(newRmGroupLink);

    lastGroup.after(newGroup);
  }

  cleanUp(id) {
    const contentSection = $(`.content-section[data-id='${id}']`);
    const type = contentSection.data('type');

    if ((type === 'html') || (type === 'rich_text')) {
      contentSection.redactor('core.destroy');
    }

    contentSection.removeAttr('contenteditable');

    $(`.cs-form[data-for='${id}']`).remove();
    $(`.cs-html-editor-overlay[data-for='${id}']`).remove();
    $(`.cs-html-editor-undo[data-for='${id}']`).remove();
    $(`.cs-hidden-editor-container[data-for='${id}']`).remove();
    $('.redactor-dropdown').remove();
    $('.redactor-toolbar-tooltip').remove();
    $(`.cs-edit[data-for='${id}']`).show();
  }

  hasUnsavedChanges(id) {
    id = $('.cs-form:first').data('for');
    if (id) {
      const form = $(`.cs-form[data-for='${id}']`);
      const contentSection = $(`.content-section[data-id='${id}']`);
      if (form && contentSection) {
        const originalHtml = this.cleanHtml(form.find('#content_section_content').val());
        const editedHtml = this.cleanHtml(contentSection.redactor('code.get'));
        return originalHtml !== editedHtml;
      }
    }
    return false;
  }

  cleanHtml(html) {
    return html.replace(/\s+/g, ' ');
  }

  // This is a hack to fix a weird issue we're seeing in direct mail and acknowledgements
  // letters where the scroll position from the previous page is getting applied on the
  // build screen - causing the page to jump to the bottom.
  scrollToTop() {
    setTimeout( () => window.scrollTo(0, 0)
    , 500);
  }

  iframed() {
    return self !== top;
  }

};

$(document).on('turbolinks:load', function() {
  if (!($('.content-section').length > 0)) { return; }
  return App.contentSectionsInst = new ContentSections;
});
