export default class ReadMore {
  constructor(el) {
    this.el = el;
    $(this.el).readmore({
      maxHeight: $(this.el).data('max-height') || 100,
      moreLink: '<a href="#" class="font-size-sm"><i class="fa fa-caret-right mr-1"></i>Show More</a>',
      lessLink: '<a href="#" class="font-size-sm"><i class="fa fa-caret-up mr-1"></i>Show Less</a>'
    });
  }
};

$(document).on('turbolinks:load', function() {
  const expandables = $('.expandable');
  if (!(expandables.length > 0)) { return; }
  expandables.each(function() {
    new ReadMore($(this));
  });
});
