import DateTimePicker from './datetimepicker';
import UserSelect from './user_select';

export default class TaskForm {
  constructor(form) {
    this.form = form;

    const descriptionTextArea = this.form.find('#task_description');
    const options = descriptionTextArea.data('options') || {};
    descriptionTextArea.redactor(options);

    const userSelect = this.form.find('.user-select')
    new UserSelect(userSelect);

    const taskDueDate = this.form.find('#task_due_date.datetime-picker');
    if (taskDueDate.length) { // non-nfgui
      new DateTimePicker(taskDueDate);
    } else { // nfgui
      new NfgUi.DateTimePicker(this.form.find('#task_due_date[data-datetimepicker="datetime"]'))
    }

    const tagListSelect = this.form.find('#task_tag_list');
    tagListSelect.select2();
  }
};
