import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["campaign", "fund"]

  connect() {
    this.enableOrDisableSubmit();
  }

  enableOrDisableSubmit() {
    if (this.campaignTarget.value === '' && this.fundTarget.value === '') {
      this.submitButton.classList.add('disabled')
    } else {
      this.submitButton.classList.remove('disabled')
    }
  }

  get submitButton() {
    return document.querySelector('#bulk_move_donations_modal a.submit')
  }
}