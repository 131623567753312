export default class Attachments {
  constructor(el) {
    this.el = el;
    this.addAttachmentBtn = $('#add_attachment');

    this.toggleFileInputs();
    this.el.on('change', 'select', () => {
      this.toggleFileInputs();
    });

    this.addAttachmentBtn.on('click', event => {
      this.addAttachment($(event.currentTarget));
      event.preventDefault();
    });

    this.el.find('a.remove-attachment').on('click', event => {
      this.removeAttachment($(event.currentTarget));
      event.preventDefault();
    });
  }

  toggleFileInputs() {
    this.el.find('select').each(function() {
      if ($(this).val() === 'new') {
        $(this).next('.upload-new-file').removeClass('d-none');
      } else {
        $(this).next('.upload-new-file').addClass('d-none');
      }
    });
  }

  addAttachment(link) {
    const lastAttachment = this.el.find('.attachment:last');
    const newAttachment = lastAttachment.clone();
    const fileInput = newAttachment.find(':file');
    const pieces = fileInput.attr('id').split('_');
    const currentIndex = parseInt(pieces[pieces.length - 2]);
    const newIndex = currentIndex + 1;

    fileInput.attr('id', fileInput.attr('id').replace(currentIndex, newIndex));
    fileInput.attr('name', fileInput.attr('name').replace(currentIndex, newIndex));
    newAttachment.find('label.custom-file-label').html('');

    lastAttachment.after(newAttachment);
  }

  removeAttachment(link) {
    link.next('input').val('1');
    link.closest('.attachment').addClass('d-none');
  }
};

$(document).on('turbolinks:load', function() {
  const el = $('form .attachments');
  if (!(el.length > 0)) { return; }
  new Attachments(el);
});
