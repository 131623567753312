import { DirectUpload } from "activestorage"

class VideoRecording {
  constructor(container) {
    // These are shared by mobile and browser recording
    this.container = container
    this.form = this.container.closest('form');
    this.videoField = this.form.find('#video_file');
    this.videoPreview = this.form.find('#video_preview');
    this.progressBar = this.form.find('#progress_bar')[0];
    this.alertNotice = this.form.find('#upload_error_alert')[0];
    this.saveButton = this.form.find(':submit')[0];

    if (this.container.data('type') == 'mobile') { // Mobile recording
      this.mobileRecordLink = this.form.find('#mobile_record_link');

      this.mobileRecordLink.click(event => {
        this.videoField.trigger('click');
        event.preventDefault();
      });

      this.videoField.change(event => {
        const file = event.target.files[0];
        this.videoPreview.removeClass('d-none').attr('src', URL.createObjectURL(file));

        // These four lines are needed for iPhone to load the video for our preview image
        this.videoPreview[0].muted = true;
        this.videoPreview[0].play();
        this.videoPreview[0].pause();
        this.videoPreview[0].muted = false;

        this.mobileRecordLink.html('Re-record Video');
        this.loadBase64EncodedImage();
      });

      window.addEventListener('direct-upload:start', event => {
        this.updateProgressBar(0);
        this.progressBar.hidden = false;
        console.log('Direct upload starting');
      });

      window.addEventListener('direct-upload:progress', event => {
        const value = event.detail.progress;
        this.updateProgressBar(value);
      });

      window.addEventListener('direct-upload:error', event => {
        event.preventDefault(); // stops Rails built-in error message

        const error = event.detail.error;
        console.error('Upload error', error);

        this.showUploadErrorMessage("It looks like your video could not be uploaded. Click 'Send' to try again.")
      });
    } else { // Browser recording
      this.videoRecorder = this.form.find('#video_recorder');
      this.startRecordingLink = this.form.find('#start_recording_link');
      this.stopRecordingLink = this.form.find('#stop_recording_link');
      this.resetRecordingLink = this.form.find('#reset_recording_link');
      this.shouldStopMediaRecorder = false;
      this.mediaRecordedIsStopped = false;
      this.mediaRecorder = null;
      this.recordedChunks = [];
      this.recordedBlob = null;
      this.countdown = this.form.find('.countdown');

      // See https://developers.google.com/web/fundamentals/media/recording-video
      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(stream => {
        this.videoRecorder[0].srcObject = stream;
        this.videoRecorder[0].play();

        this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
        this.mediaRecorder.addEventListener('dataavailable', e => {
          if (e.data.size > 0) {
            this.recordedChunks.push(e.data);
          }

          if(this.shouldStopMediaRecorder && !this.mediaRecordedIsStopped) {
            this.mediaRecorder.stop();
            this.mediaRecordedIsStopped = true;
          }
        });

        this.mediaRecorder.addEventListener('stop', () => {
          this.recordedBlob = new Blob(this.recordedChunks);
          this.videoRecorder.addClass('d-none');
          this.videoPreview.removeClass('d-none').attr('src', URL.createObjectURL(this.recordedBlob));
          this.resetRecordingLink.removeClass('d-none');
          this.loadBase64EncodedImage();
        });

        document.addEventListener('turbolinks:before-render', () => {
          stream.getAudioTracks().forEach(function(track) {
            track.stop();
          });

          stream.getVideoTracks().forEach(function(track) {
            track.stop();
          });
        });
      }).catch(error => console.error(error));

      this.startRecordingLink.click(event => {
        this.countdown.addClass('play');
        this.startRecordingLink.addClass('disabled');

        setTimeout(() => {
          this.countdown.removeClass('play');
          this.startRecording();
        }
        , 3000);

        event.preventDefault();
      });

      this.stopRecordingLink.click(event => {
        this.stopRecording();
        event.preventDefault();
      });

      this.resetRecordingLink.click(event => {
        this.resetRecording();
        event.preventDefault();
      });

      this.form.on('submit', event => {
        if (!this.recordedBlob) { return; }

        // Prevents the form from submitted while allowing Rail's disable with
        // to do its thing.
        event.preventDefault();

        const url = this.videoField[0].dataset.directUploadUrl

        this.recordedBlob.lastModifiedDate = new Date();
        this.recordedBlob.name = 'browser_recording.webm';

        // This is how you use ActiveStorage without a file input field.
        // See https://edgeguides.rubyonrails.org/active_storage_overview.html
        const upload = new DirectUpload(this.recordedBlob, url, this)
        upload.create((error, blob) => {
          if (error) {
            this.showUploadErrorMessage(error);
          } else {
            const hiddenField = this.form.find('#blob_signed_id');
            hiddenField.val(blob.signed_id);
            this.form.off('submit').submit();
          }
        })
      });
    }
  }

  startRecording() {
    this.mediaRecorder.start(250);
    this.startRecordingLink.addClass('d-none');
    this.stopRecordingLink.removeClass('d-none');
  }

  stopRecording() {
    this.shouldStopMediaRecorder = true;
    this.stopRecordingLink.addClass('d-none');
  }

  resetRecording() {
    this.shouldStopMediaRecorder = false;
    this.mediaRecordedIsStopped = false;
    this.recordedChunks = [];
    this.videoPreview.addClass('d-none');
    this.resetRecordingLink.addClass('d-none');
    this.stopRecordingLink.addClass('d-none');
    this.videoRecorder.removeClass('d-none');
    this.startRecordingLink.removeClass('d-none disabled');
  }

  directUploadWillStoreFileWithXHR(request) {
    this.progressBar.hidden = false;
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    const value = (event.loaded / event.total) * 100;
    this.updateProgressBar(value);
  }

  showUploadErrorMessage(error) {
    this.progressBar.hidden = true;
    this.alertNotice.textContent = error
    this.alertNotice.hidden = false;

    setTimeout(() => {
      Rails.enableElement(this.saveButton);
    }
    , 250);

    this.saveButton.addEventListener('click', () => this.alertNotice.hidden = true, { once: true });
  }

  loadBase64EncodedImage() {
    // This fires after recording is complete and the video preview is loaded.
    // The video is drawn in a canvas tag.  We then use FileReader to read
    // base46 image data and store that in a hidden field that is submitted to
    // the server.  Once on the server, we decode the image data and assign that
    // to the video's image active storage attachment.  See maybe_set_image in
    // video_acknowledgements_controller.rb.
    const canvas = this.form.find('#image_canvas')[0];
    const video = this.videoPreview[0];
    const base64EncodedImage = this.form.find('#base64_encoded_image');

    setTimeout(() => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      canvas.toBlob(function(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          base64EncodedImage.val(this.result);
        }
      });
    }
    , 1000);
  }

  updateProgressBar(value) {
    // This covers both legacy and NfgUI progress bars
    this.progressBar.value = value;
    $(this.progressBar).find('.progress-bar[role="progressbar"]').attr('aria-valuenow', value).css('width', value + '%');
  }
};

$(document).on('turbolinks:load', function() {
  const container = $('#video_recording');
  if (!(container.length > 0)) { return; }
  new VideoRecording(container);
});
