import Loader from './loader';

class TasksNavItem {
  constructor(el) {
    var url = '/tasks_nav_item';
    $.getScript(url);
  }
};

$(document).on('turbolinks:load', function() {
  const el = $('#tasks_nav_item');
  if (el.length == 0) { return; }
  if (el.hasClass('loaded')) { return; }

  new TasksNavItem(el);
});
