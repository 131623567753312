class OnboardingSaveAndExit {
  constructor(form) {
    this.form = form;
    const body = this.form.closest('body');
    this.link = body.find('#save_and_exit');
    if(this.link.length)
      this.bindEvents()
  }

  bindEvents() {
    this.form.on('submit', e => { this.link.addClass('disabled'); });
    this.link.on('click', e => {
      const action = this.form.attr("action");
      this.form.attr("action", action + "?exit=true");
      this.form.submit();
      e.preventDefault();
    });
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#onboarding_main_form');
  if (!(form.length > 0)) { return; }
  new OnboardingSaveAndExit(form);
});