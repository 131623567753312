class MailingCreatorSetupForm {
  constructor(form) {
    this.form = form;
    this.labelTypeSelect = this.form.find('select[name="onboarding_mailing_creator_setup[label_type]"]');
    this.labelFields = this.form.find('#label_fields');

    this.labelTypeSelect.change(() => {
      this.toggleLabelFields();
    });
    this.toggleLabelFields();
  }

  toggleLabelFields() {
    if (!!this.labelTypeSelect.val()) {
      this.labelFields.removeClass('d-none');
    } else {
      this.labelFields.addClass('d-none');
    }
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('body.onboarding-mailing_creator.setup form#onboarding_main_form');
  if (!(form.length > 0)) { return; }
  new MailingCreatorSetupForm(form);
});
