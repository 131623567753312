class SoftCreditsForm {
  constructor(form) {
    this.form = form;
    this.quickAddModal = $('#quick_add_user_modal');
    this.form.on('click', '[data-target="#quick_add_user_modal"]', event => {
      const fieldId = $(event.target).closest('.form-group').find('select.user-select').attr('id');
      this.quickAddModal.find('input[name="user_select_field_id"]').val(fieldId);
    });
  }
};

$(document).on('turbolinks:load', function() {
  const form = $('form#soft-credits-form');
  if (!(form.length > 0)) { return; }
  new SoftCreditsForm(form);
});
