export default class UserSelect {
  constructor(el) {
    // There is a known issue with select2 inside bootstrap's modals when trying to auto-focus the seach text input.
    // The typical accepted solution is to remove the tabindex attribute from the modal div when the modal is open.
    // However, In IE, the tabindex is not a removable attribute (so: $("element").removeAttr 'tabindex' doesn't work)
    //
    // IE Friendly Solution: change the dropdownParent for select2 if the select2 is in a modal.

    let modalOrBodyDropdownParent;
    this.el = el;
    const modal = this.el.closest('.modal');
    const resource = this.el.data('resource');
    const exception = this.el.data('exception');

    if (modal.length > 0) { // check for length, otherwise returns: jQuery []
      modalOrBodyDropdownParent = modal;
    } else {
      modalOrBodyDropdownParent = $("body");
    }

    this.el.select2({
      dropdownParent: modalOrBodyDropdownParent,
      minimumInputLength: 1,
      ajax: {
        delay: 250,
        url: `/${resource}.json`,
        data(params) {
          return {
            'q[id_not_eq]': exception,
            'q[first_last_name_cont]': params.term,
            page: params.page
          };
        },
        processResults(users) {
          const results = [];
          $.each(users, (index, user) =>
            results.push({
              id: user.id,
              text: user.name
            })
          );
          return { results };
        }
      }});

    // Prevents the placeholder from being truncated if select2 is hidden when
    // initialized (recipients step of email onboarder).
    if (this.el.is(':hidden') && !this.el.val()) {
      this.el.next('.select2-container').find('input.select2-search__field').width(225);
    }
  }
};

$(document).on('turbolinks:load', function() {
  const el = $('select.user-select');
  if (!(el.length > 0)) { return; }
  el.each(function() {
    new UserSelect($(this));
  });
  // Set focus to search text field when we click on select 2 drop down
  $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  });
});
